import React, { Fragment } from 'react';
import { observer, inject } from 'mobx-react'; 
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ListSubheader from '@material-ui/core/ListSubheader';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import BookingListItem from './BookingListItem';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
//import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import getWeek from 'date-fns/getWeek';
const moment = require('moment');



const styles = theme => ({
  list: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    //marginTop: 30,
  },
  root: {
    flexGrow: 1,
    ...theme.mixins.gutters(),
    //paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  mainPaper: {
    //marginTop: 40,
    paddingBottom: 60,
  },
  header: {
    textAlign: 'center',
    //paddingTop: theme.spacing.unit * 2,
    padding: theme.spacing(3),
  },
  boxIcon: {
    margin: 20,
    color: 'white',
    fontSize: 40, 
  },
});

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class BookingsList extends React.Component {
  state={
    expanded: false,
    cancelled: false,
    tab: 0,
    selectReport: false,
  }
  

  handleExpanded = event =>{
    this.setState({
      expanded: event.target.checked,
    });
  }
  handleCancelled = event =>{
    this.setState({
      cancelled: event.target.checked,
    });
  }

  handleChangeTab = (event, value) => {
    this.setState({ tab: value });
  };

  vehicleReport = ()=>{
    this.setState({ selectReport: false });
    
    const cars = this.props.appState.bookings.data.transfers.filter(obj=>obj.CARREG!=='')
          .map((obj)=>({'make': obj.vehiclemake, 'reg':obj.CARREG, 'phv': obj.vehiclephv}));
    
    const jsonObject = cars.map(JSON.stringify); 
    const uniqueSet = new Set(jsonObject); 
    const uniqueArray = Array.from(uniqueSet).map(JSON.parse); 
    const week = getWeek(new Date(this.props.appState.bookings.date), {weekStartsOn: 1})
    const fileName = `week(${week})-TfL Vehicle Data File Format.csv`;
    const titles = 'VRM,Vehicle make,Vehicle licence number\r\n';
    let theText = titles; 
    uniqueArray.map(item=>(theText += `${item.reg},${item.make},${item.phv}\r\n`));
    this.makeReport(fileName, theText);      
  }

  driverReport =()=>{
    this.setState({ selectReport: false });
    
    const drivers = this.props.appState.bookings.data.transfers.filter(obj=>obj.DRIVER!=='')
            .map((obj)=>({'name': obj.drivername, 'surname': obj.DRIVER, 'phv': obj.driverphv}));
    
    const jsonObject = drivers.map(JSON.stringify); 
    const uniqueSet = new Set(jsonObject); 
    const uniqueArray = Array.from(uniqueSet).map(JSON.parse); 
    const week = getWeek(new Date(this.props.appState.bookings.date), {weekStartsOn: 1})
    const fileName = `week(${week})-TfL Driver Data File Format.csv`;
    const titles = 'Private hire Driver Licence Number,Forename,Surname\r\n';
    let theText = titles; 
    uniqueArray.map(item=>(theText += `${item.phv},${item.name},${item.surname}\r\n`));

    this.makeReport(fileName, theText);         
  }


  makeReport =(fileName, theText)=>{
    const qry = {
      'filename': fileName,
      'data': theText,
    }

    fetch(this.props.appState.appRoutine.apiUrl + 'reports/create', 
      {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(qry),
      })
      .then(res => res.json())
      .then(
        (result) => {
            this.props.appState.appRoutine.loading = false;
          if(result.status === 'err'){
            alert(result)
            console.log(result);
          }else{
          this.props.appState.appRoutine.msg = {
            text: 'Report Created',
            open: true,
            type: 'success',
          }
          //this.props.reload();
          };
        },
        (error) => {
            this.props.appState.appRoutine.loading = false;
            alert(error)
        }
      )
    
  }


  render() {
    const { classes } = this.props;
    const dates = this.props.appState.bookings.dates;
    const data = this.props.appState.bookings.data;
    

    if(data.transfers.length>0){
      return (
        <div>
        <Grid container className={classes.root} spacing={6}>
          <Grid item xs={12}>
          
            <Paper className={classes.mainPaper} elevation={1}>
              <ListSubheader component="div" id="nested-list-subheader" style={{marginBottom: 15, display: 'flex'}}>
              <div style={{flexGrow: 1, display: 'flex', marginTop: 10, marginleft: 10}}>
                {new Set(data.transfers.filter(obj=>obj.CARREG!=='').map(obj=>obj.CARREG)).size < 10 ?
                <Typography variant="button" style={{margin: 12}} >
                  CARS: {new Set(data.transfers.filter(obj=>obj.CARREG!=='').map(obj=>obj.CARREG)).size}
                </Typography>
                :
                <Typography variant="button" style={{margin: 12}} color="secondary">
                  CARS:  {new Set(data.transfers.filter(obj=>obj.CARREG!=='').map(obj=>obj.CARREG)).size}
                </Typography>
                }
                {this.props.appState.bookings.period==='week' &&
                  <Button onClick={(event)=>{this.setState({selectReport: true})}}>REPORT</Button>
                }
              </div>
              <div style={{flexShrink: 1, textAlign: 'right', paddingTop: 10, marginRight: 10}}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={this.state.expanded}
                          onChange={this.handleExpanded}
                        />
                      }
                      label="Expand"
                    />
                    <FormControlLabel
                      control={
                        <Switch
                          checked={this.state.cancelled}
                          onChange={this.handleCancelled}
                        />
                      }
                      label="Xld"
                    />
                </div>
                </ListSubheader>
                <Divider />

                <div className={classes.list}>
                  {dates.transfers.map((theDate, index)=>
                    <List
                      key={index}
                      component="nav"
                      subheader={
                        <ListSubheader component="div" style={{color: '#E91E63'}}>
                          <div style={{float: "left", width: "50%"}}>{theDate}</div>
                          <div style={{float: "right", width: "50%", textAlign: "right"}}>Total: {data.transfers.filter(transfer => moment(transfer.DATE).format('DD-MMM-YYYY') === theDate).length}</div>
                        </ListSubheader>}
                    >
                      {data.transfers.filter(transfer => moment(transfer.DATE).format('DD-MMM-YYYY') === theDate).map((item, index)=>
                        
                        <div key={index}>
                          {(this.state.cancelled===false && item.STATUS === 'CANCELLED') ? 
                          <Fragment>
                          </Fragment>
                          :
                          <Fragment>
                            <Divider light/>
                            <BookingListItem item={item} expanded={this.state.expanded} refresh={this.props.refresh}/>
                          </Fragment>
                          }
                        </div>
                        
                      )}
                    </List>
                  )}
                </div>
              
            </Paper>
          </Grid>
        </Grid>



        <Dialog
            open={this.state.selectReport}
            TransitionComponent={Transition}
            keepMounted
            //maxWidth="xs"
            //fullWidth
            onClose={()=>{this.setState({selectReport: false})}}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            
          >
            <DialogTitle id="alert-dialog-slide-title">{"Select Report"}</DialogTitle>
            <DialogContent dividers scroll style={{textAlign: 'center'}}>
              <List component="nav" className={classes.list}>
                <ListItem button onClick={this.driverReport}>
                  <ListItemText primary={
                      <Typography
                        component="span"
                        variant="body1"
                        className={classes.inline}
                        color='textPrimary'
                      >
                        Tfl Driver Data Report
                      </Typography>
                      }
                    />
                </ListItem>
                <ListItem button onClick={this.vehicleReport}>
                  <ListItemText primary={
                      <Typography
                        component="span"
                        variant="body1"
                        className={classes.inline}
                        color='textPrimary'
                      >
                        Tfl Vehicle Data Report
                      </Typography>
                      }
                    />
                </ListItem>
              </List>
            </DialogContent>
            <DialogActions>
              <Button onClick={()=>{this.setState({selectReport: false})}} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>  
        </div>
      );
  }else{
    return (
      <Grid container className={classes.root} spacing={6}>
        <Grid item xs={12}>
          <Paper elevation={1}>
            <div>
                <Typography variant="subtitle1" gutterBottom className={classes.header}>
                  No services found for the dates selected.
                </Typography>
            </div>
          </Paper>
        </Grid>
      </Grid>
    );
  }
  }
}

BookingsList.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(inject('appState')(observer(BookingsList)));