import React, { Component } from 'react';
import { observer, inject } from 'mobx-react'; 
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';


const styles = theme => ({
  root: {
    //flexGrow: 1,
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    backgroundColor: 'RosyBrown',
    textAlign: 'center',
  },
});

class Footer extends Component {
  render() {
    const {classes} = this.props;

    return (
        <div className={classes.root}>
          <Typography variant='caption' style={{color: 'white'}}>
            Royal Travel Club
          </Typography>
        </div>
    );
  }
}



export default withStyles(styles)(inject('appState')(observer(Footer)));