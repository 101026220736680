import React, { Component } from 'react';
import { observer, inject } from 'mobx-react'; 
import {BrowserRouter as Router, Route, Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText  from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

//icons
import MenuIcon from '@material-ui/icons/Menu';
import InfoIcon from '@material-ui/icons/Info';
import ExitIcon from '@material-ui/icons/ExitToApp';
import BookingsIcon from '@material-ui/icons/Today';
import ViewBookingIcon from '@material-ui/icons/EventNote';
import DriversIcon from '@material-ui/icons/PermContactCalendar';
import VehiclesIcon from '@material-ui/icons/DirectionsCar';
import SuppliersIcon from '@material-ui/icons/EmojiTransportation';
import ReportsIcon from '@material-ui/icons/Folder';

import About from './Components/About';
import Login from './Components/Login';
import Footer from './Components/Footer';
import Bookings from './Components/Bookings';
import ViewBooking from './Components/ViewBooking';
import Drivers from './Components/Drivers';
import Vehicles from './Components/Vehicles';
import Suppliers from './Components/Suppliers';
import EditBooking from './Components/EditBooking';
import Reports from './Components/Reports';
import Invoices from './Components/Invoices';



const drawerWidth = 240;

//-----STYLES-----//
const styles = theme => ({
  root: {
    flexGrow: 1,
    //height: 430,
    zIndex: 1,
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
    width: '100%',
  },
  flex: {
    flex: 1,
  },
  appBar: {
    position: 'reletive',
    marginLeft: drawerWidth,
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
    float: 'left'
  },
  navIconHide: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    [theme.breakpoints.up('md')]: {
      position: 'fixed',
    },
  },
  main: {
    flexGrow: 1,
    minHeight: 1080,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    backgroundColor: theme.palette.background.default,
    //padding: theme.spacing.unit * 1,
    marginTop: 64,
    marginLeft: -2 ,
    [theme.breakpoints.up('md')]: {
      marginLeft: 240 - 2,
    },
  },
  content: {
    flexGrow: 1,
  },
  footer: {
    flexShrink: 0,
  }
});

const iconInfo = (<InfoIcon/>);
const iconBookings = (<BookingsIcon/>);
const iconViewBooking = (<ViewBookingIcon/>);
const iconDrivers = (<DriversIcon/>);
const iconVehicles = (<VehiclesIcon/>);
const iconSuppliers = (<SuppliersIcon/>);
const iconReports = (<ReportsIcon/>);

//----- CLASS -----//
class AppRoutes extends Component {
  state = {
    mobileOpen: false,
    anchorEl: null,
  };

  
  //--Routes >>>>>
  routes = {
    admin: [
      
      {route: '/Bookings', text: 'Bookings', component: Bookings, icon: iconBookings, Navlink: true},
      {route: '/ViewBooking', text: 'View Booking', component: ViewBooking, icon: iconViewBooking, Navlink: true},
      {route: '/Drivers', text: 'Drivers', component: Drivers, icon: iconDrivers, Navlink: true},
      {route: '/Vehicles', text: 'Vehicles', component: Vehicles, icon: iconVehicles, Navlink: true},
      {route: '/Suppliers', text: 'Suppliers', component: Suppliers, icon: iconSuppliers, Navlink: true},
      {route: '/EditBooking', text: 'Edit Booking', component: EditBooking, icon: iconInfo, Navlink: false},
      {route: '/Reports', text: 'Reports', component: Reports, icon: iconReports, Navlink: true},
      {route: '/Invoices', text: 'Invoices', component: Invoices, icon: iconReports, Navlink: true},
      {route: '/About', text: 'About', component: About, icon: iconInfo, Navlink: false},
      
    ],
    driver: {},
  }

  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };

  handleLogout = () => {
    this.setState({ anchorEl: null });
    this.props.appState.logout();
  }

  render() {
    const {appState, classes, theme} = this.props;
    //const { anchorEl } = this.state;
    //const open = Boolean(anchorEl);

    const theRoutes = this.routes.admin;
    
    function Alert(props) {
      return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const drawer = (
      <div>
        <div className={classes.toolbar} >
          <Typography varian='h6' style={{paddingLeft: 15, paddingTop: 10}}>DeluxeMotion</Typography>
    <Typography varian='body2' style={{paddingLeft: 15, paddingTop: -5}}><small>System v. {appState.appRoutine.version}</small></Typography>
        </div>
        
        <Divider />
        <List>
          {theRoutes.filter(item => {return item.Navlink === true}).map((item, index) =>
            <Link to={item.route} key={index}>
              <ListItem button onClick={()=>appState.changePage(item.text)}>
                <ListItemIcon style={{color: 'DarkSlateBlue'}}>{item.icon}</ListItemIcon>
                <ListItemText primary={item.text} style={{color: 'DarkSlateBlue'}}/>
              </ListItem>
            </Link>
          )}
        </List>
      </div>
    );

    const renderRoutes = (
      <div>
        <Route exact path="/" component={theRoutes[0].component}/>
        {theRoutes.map((item, index)=>
          <div key={index}>
            <Route path={item.route} component={item.component} />
          </div>                
        )}
      </div>
    )

    

    if(appState.appRoutine.logedIn===false){
        return (
          <Login/>
        );
    }else{
        return (
          <Router>
          <div className={classes.root}>

          <AppBar className={classes.appBar} id="appBar">
              <Toolbar>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={this.handleDrawerToggle}
                  className={classes.navIconHide}
                >
                  <MenuIcon />
                </IconButton>
                <Typography variant="h6" color="inherit" noWrap  style={{width: 200}}>
                  {this.props.appState.appRoutine.currentPage}
                </Typography>
                {
                  <div style={{position: 'reletive', width: 'calc(100% - 200px)',}}>
                      <IconButton style={{float: 'right'}} 
                        onClick={()=>appState.logout()}
                        color="inherit"
                      >
                        <ExitIcon />
                      </IconButton>
                  </div>
                }
              </Toolbar>
          </AppBar>

           <Hidden mdUp>
              <Drawer
                variant="temporary"
                anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                open={this.state.mobileOpen}
                onClose={this.handleDrawerToggle}
                classes={{
                  paper: classes.drawerPaper,
                }}
                ModalProps={{
                  keepMounted: true, // Better open performance on mobile.
                }}
              >
                {drawer}
              </Drawer>
            </Hidden>
            <Hidden smDown implementation="css">
              <Drawer
                variant="permanent"
                open
                classes={{
                  paper: classes.drawerPaper,
                }}
              >
                {drawer}
              </Drawer>
            </Hidden>
      
            <hr/>
            <main className={classes.main}>
              <LinearProgress hidden={!this.props.appState.appRoutine.loading} color="secondary"/>
              <div className={classes.content} >{renderRoutes}</div>
              <Snackbar open={this.props.appState.appRoutine.msg.open} autoHideDuration={2000} onClose={()=>this.props.appState.appRoutine.msg.open = false}>
                <Alert onClose={()=>this.props.appState.appRoutine.msg.open = false} severity={this.props.appState.appRoutine.msg.type}>
                  {this.props.appState.appRoutine.msg.text}
                </Alert>
              </Snackbar>
              <Footer className={classes.footer}/>
            </main> 
          </div>
      </Router>
      );
    }//end if logedIn
  }
}
AppRoutes.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};
export default withStyles(styles, { withTheme: true })(inject('appState')(observer(AppRoutes)));