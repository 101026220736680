import React, { Component } from 'react';
import { observer, inject } from 'mobx-react'; 
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import ViewBookingList from './ViewBookingList';
import { isUndefined } from 'util';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import {BrowserRouter as Router, Route, Link} from 'react-router-dom';
import EditBooking from './EditBooking';

const styles = theme => ({  
  root: {
    ...theme.mixins.gutters(),
    //paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing(2),
  },
  formControl: {
    marginLeft: theme.spacing(1),
    //width: "100%",
  },
  mainGrid: {
    paddingTop: theme.spacing(3),
  }
});

class ViewBooking extends Component {
  state={
    searchDisabled: true,
  }

  componentDidMount(){
    if(!isUndefined(this.props.location.state)){
        this.props.appState.viewBooking.ref = this.props.location.state.ref;
        this.doSearch(this.props.location.state.ref);
      }
      else if(this.props.appState.viewBooking.ref!==''){
        this.doSearch(this.props.appState.viewBooking.ref)
        //this.setState({ref: this.props.appState.viewBooking.ref});
      }
  }


  handleChecked = name => event => {
    //this.setState({ [name]: event.target.checked });
    this.props.appState.viewBooking[name] = event.target.checked;
  };
  handleSelect = event => {
    this.props.appState.viewBooking.ref = event.target.value;
    if(this.props.appState.viewBooking.ref.length>3){
        this.setState({searchDisabled: false})
    }else{
        this.setState({searchDisabled: true})
    }
  };

  handleClick = () => {
    //const ref = this.props.appState.viewBooking.ref;
    this.doSearch()
  };

  doSearch = () => {
    this.props.appState.appRoutine.loading = true; 
    //this.props.appState.viewBooking.ref = ref;

    let qry = {
      "mode": "byref",
      "searchFor": this.props.appState.viewBooking.ref,
    };


    fetch(this.props.appState.appRoutine.apiUrl + 'transfers/search', 
      {
      method: 'POST', // or 'PUT'
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(qry),
      })
      .then(res => res.json())
      .then(
        (result) => {
            this.props.appState.appRoutine.loading = false;
          if(result.status === 'err'){
            alert(result)
          }else {
            this.props.appState.viewBooking.dates.transfers = result.dates;
            this.props.appState.viewBooking.data.transfers = result.data;
          }
        },
        (error) => {
            this.props.appState.appRoutine.loading = false;
            alert(error)
        }
      )
    
  }

  render() {
    const {classes} = this.props;

    return (
        <div>
          <Paper className={classes.root} elevation={1} style={{minHeight: 55, backgroundColor: '#ECEFF1'}}>
          <Grid container spacing={2} className={classes.mainGrid}>
            <Grid item xs={8} md={9} lg={10} xl={10} style={{display: 'flex'}}>
                <FormControl className={classes.formControl}>
                    <Input id="searchFor" name="searchFor" value={this.props.appState.viewBooking.ref} onChange={this.handleSelect} />
                    <FormHelperText>Ref</FormHelperText>
                  </FormControl>
                  <Button 
                    color="primary"
                    style={{marginLeft: 8}}
                    onClick={this.handleClick}
                    disabled={this.state.searchDisabled}
                    >
                    Search
                  </Button>
            </Grid>
            <Grid item xs={4} md={3} lg={2} xl={2} style={{textAlign: 'right'}}>
              
            </Grid>
          </Grid>
        </Paper>

          {this.props.appState.viewBooking.data.transfers.length>0 && 
          <div>
            <Router>
              <Route path="/EditBooking" component={EditBooking}/>
            </Router>
            <Link to={{pathname: '/EditBooking', state: {ref: this.props.appState.viewBooking.data.transfers[0].REF}}} onClick={()=>{this.props.appState.appRoutine.currentPage='New Booking'}}>
              <Fab color="secondary" aria-label="Add" style={{position: 'fixed', margin: 40, right: 30, bottom: 30}}>
                <AddIcon/>
              </Fab>
            </Link>
          </div>
          }
        <div>
          <ViewBookingList refresh={this.doSearch}/>
        </div>
        </div>
    );
  }
}



export default withStyles(styles)(inject('appState')(observer(ViewBooking)));