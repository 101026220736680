import React from 'react';
import { observer, inject } from 'mobx-react'; 
import PropTypes from 'prop-types';
import Logo from './../Images/logo.png'
//import ajax from 'superagent';
import classNames from 'classnames';

import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';

const styles = {
  card: {
    maxWidth: 380,
    minWidth: 340,
    margin: 0,
    padding: 20,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  media: {
    height: 268,
    width: 345,
    margin: 'auto',
  },
  cardActions: {
    textAlign: 'center',
    marginTop: 20,
    marginBottom: 20,
  }
};


class Login extends React.Component {
  state = {
    login: '',
    password: '',
    showPassword: false,
    progressBarHidden: true,
    loginFail: false,
  };


  handleChange = prop => event => {
    this.setState({ [prop]: event.target.value });
  };


  handleMouseDownPassword = event => {
    event.preventDefault();
  };

  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  handleTryAgain = () => {
    this.setState({loginFail: false});
  }

  handleLogin = () => {
    this.setState({progressBarHidden: false});
    let qry = {
      "login": this.state.login,
      "pass": this.state.password,
    };
    fetch(this.props.appState.appRoutine.apiUrl + 'account/login', 
      {
      method: 'POST', // or 'PUT'
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(qry),
      })
      .then(res => res.json())
      .then(
        (result) => {
          if(result.user.length>0){
            this.setState({progressBarHidden: true,});
            this.props.appState.login(result.user[0]);
          } else {
            this.setState({
              progressBarHidden: true,
              loginFail: true,});
          }
        },
        (error) => {
          this.setState({progressBarHidden: true,});
          alert(error);
        }
      )
  }


  render(){
    const { classes } = this.props;

    if(this.state.loginFail===false){
      return (
        <div>
          <Card className={classes.card} style={{textAlign: 'center'}}>
                <CardMedia
                  className={classes.media}
                  image={Logo}
                  title="DeluxeMotion"
                />
                <CardContent>
                  <LinearProgress hidden={this.state.progressBarHidden}/>
                  <Typography gutterBottom variant="h5" component="h5" style={{marginTop: 10}}>
                    LOGIN
                  </Typography>
                  <Typography variant="body1">
                    Please enter your login details to proceed.
                  </Typography>
                  <TextField
                    id="login"
                    label="login"
                    value={this.state.value}
                    defaultValue=""
                    className={classes.textField}
                    margin="normal"
                    style={{width: '90%'}}
                    onChange={this.handleChange('login')}
                  />
                  <FormControl className={classNames(classes.margin, classes.textField)} style={{width: '90%'}}>
                    <InputLabel htmlFor="adornment-password">Access code</InputLabel>
                    <Input
                      id="adornment-password"
                      type={this.state.showPassword ? 'text' : 'password'}
                      value={this.state.password}
                      onChange={this.handleChange('password')}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="Toggle password visibility"
                            onClick={this.handleClickShowPassword}
                            onMouseDown={this.handleMouseDownPassword}
                          >
                            {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </CardContent>
                <CardActions className={classes.cardActions}>
                  <Grid container spacing={5}>
                    <Grid item xs={12}>
                      <Button size="small" color="primary" style={{width: '50%'}} onClick={this.handleLogin}>
                        LOG IN
                      </Button>
                    </Grid>
                  </Grid>
                </CardActions>
              </Card>
        </div>
      );
  }else {
    return (
      <div>
         <Card className={classes.card} style={{textAlign: 'center'}}>
         <CardMedia
                  className={classes.media}
                  image={Logo}
                  title="DeluxeMotion"
                />
              <CardContent>
                <LinearProgress hidden={this.state.progressBarHidden}/>
                <Typography gutterBottom variant="h5" component="h5" style={{marginTop: 10}}>
                  LOGIN
                </Typography>
                <Typography variant="body1">
                  An account with these login details could not be found. 
                  Please check your credentials and try again. 
                </Typography>
                
              </CardContent>
              <CardActions className={classes.cardActions}>
              <Grid container spacing={5}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Button size="small" color="primary" style={{width: '50%'}} onClick={this.handleTryAgain}>
                    TRY AGAIN
                  </Button>
                </Grid>
                </Grid>
              </CardActions>
            </Card>
      </div>
    );    
  }
  //end if
}
}

Login.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(inject('appState')(observer(Login)));