import React, { Component } from 'react';
import { observer, inject } from 'mobx-react'; 
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import DateFnsUtils from '@date-io/date-fns';
import { format } from 'date-fns';
import gbLocale from "date-fns/locale/en-GB";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { KeyboardDatePicker } from "@material-ui/pickers";
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import {BrowserRouter as Router, Route, Link} from 'react-router-dom';
import BookingList from './BookingList';
import EditBooking from './EditBooking';
import Jobs from './Jobs';


const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    //paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing(2),
  },
  formControl: {
    marginLeft: theme.spacing(1),
    width: "100%",
  },
  mainGrid: {
    paddingTop: theme.spacing(3),
  }
});


class Bookings extends Component {
  state={
 
  }

  componentDidMount(){
    if(this.props.appState.bookings.date===''){
      this.props.appState.bookings.date = new Date();
    }
    this.doSearch();
  }


  handleDateChange = (date) => {
    this.props.appState.bookings.date = date; 
    if(this.props.appState.bookings.activeSearch===false){
      this.doSearch()
    }
  }

  handleChecked = name => event => {
    //this.setState({ [name]: event.target.checked });
    this.props.appState.bookings[name] = event.target.checked;
  };
  handleSelect = event => {
    this.props.appState.bookings[event.target.name] = event.target.value;
  };

  handleClick = () => {
    this.doSearch(true)
  };

  doSearch = () => {
    this.props.appState.appRoutine.loading = true; 


    let qry = {
      "mode": "bydate",
      "date": format(this.props.appState.bookings.date, 'dd/MM/yyyy'),
    };

    if(this.props.appState.bookings.activeSearch===true){
      qry = {
        "mode": "search",
        "date": format(this.props.appState.bookings.date, 'dd/MM/yyyy'),
        "period": this.props.appState.bookings.period,
        "searchBy": this.props.appState.bookings.searchBy,
        "searchFor": this.props.appState.bookings.searchFor,
      }
    }

    fetch(this.props.appState.appRoutine.apiUrl + 'transfers/search', 
      {
      method: 'POST', // or 'PUT'
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(qry),
      })
      .then(res => res.json())
      .then(
        (result) => {
            this.props.appState.appRoutine.loading = false;
          if(result.status === 'err'){
            alert('err')
            console.log(result)
          }else {
            this.props.appState.bookings.dates.transfers = result.dates;
            this.props.appState.bookings.data.transfers = result.data;

          }
        },
        (error) => {
            this.props.appState.appRoutine.loading = false;
            alert('error')
            console.log(error)
        }
      )
    
  }

  render() {
    const {classes} = this.props;

    return (
        <div>
          <Paper className={classes.root} elevation={1} style={{minHeight: 55, backgroundColor: '#ECEFF1'}}>
          <Grid container spacing={2} className={classes.mainGrid}>
            <Grid item xs={8} md={9} lg={10} xl={10} >
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={4} xl={2} >
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={gbLocale}>
                      <KeyboardDatePicker
                        //clearable
                        value={this.props.appState.bookings.date}
                        placeholder="Expiry Date"
                        onChange={date => this.handleDateChange(date)}
                        //minDate={new Date()}
                        format="dd/MM/yyyy"
                        style={{marginLeft: 10, marginBottom: 15, flexGrow: 1}}
                      />
                      </MuiPickersUtilsProvider>
                </Grid>
                {this.props.appState.bookings.activeSearch ? (
                  <Grid item xs={12} md={6} lg={8} xl={8} >
                    <Grid container spacing={2}>
                    <Grid item xs={12} md={12} lg={3} xl={3} >
                      <FormControl className={classes.formControl}>
                      <Select
                        value={this.props.appState.bookings.period}
                        onChange={this.handleSelect}
                        displayEmpty
                        name="period"
                      >
                        <MenuItem value="week">Selected week</MenuItem>
                        <MenuItem value="month">Selected month</MenuItem>
                        <MenuItem value="year">Selected year</MenuItem>
                      </Select>
                      <FormHelperText>Period</FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={12} lg={3} xl={3} >
                    <FormControl className={classes.formControl}>
                      <Select
                        value={this.props.appState.bookings.searchBy}
                        onChange={this.handleSelect}
                        displayEmpty
                        name="searchBy"
                      >
                        <MenuItem value="NAME">Name</MenuItem>
                        <MenuItem value="CARREG">Vehicle</MenuItem>
                        <MenuItem value="DRIVER">Driver</MenuItem>
                        <MenuItem value="AGENT">Agent</MenuItem>
                        <MenuItem value="AGENTSREF">Agent's ref</MenuItem>
                        <MenuItem value="FROMTITLE">From</MenuItem>
                        <MenuItem value="FROMDETAILS">From-details</MenuItem>
                        <MenuItem value="TOTITLE">To</MenuItem>
                        <MenuItem value="TODETAILS">To-details</MenuItem>
                      </Select>
                      <FormHelperText>Search by</FormHelperText>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={12} lg={3} xl={3} >
                  <FormControl className={classes.formControl}>
                    <Input id="searchFor" name="searchFor" value={this.props.appState.bookings.searchFor} onChange={this.handleSelect} />
                    <FormHelperText>Search for</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={12} lg={3} xl={3} >
                  <Button 
                    color="primary"
                    style={{width: "100%"}}
                    onClick={this.handleClick}>
                    Search
                  </Button>
                </Grid>
                </Grid>
              </Grid>
                ) : null}
              </Grid>
            </Grid>
            <Grid item xs={4} md={3} lg={2} xl={2} style={{textAlign: 'right'}}>
              <FormControlLabel
                  label="Search"
                  style={{marginLeft: 15}}
                  control={
                    <Switch
                    checked={this.props.appState.bookings.activeSearch}
                    onChange={this.handleChecked('activeSearch')}
                    value="activeSearch"
                    />
                  }
                />
            </Grid>
          </Grid>
        </Paper>

          <div>
            <Router>
              <Route path="/EditBooking" component={EditBooking}/>
            </Router>
            <Link to={{pathname: '/EditBooking'}} onClick={()=>{this.props.appState.appRoutine.currentPage='New Booking'}}>
              <Fab color="secondary" aria-label="Add" style={{position: 'fixed', margin: 40, right: 30, bottom: 30}}>
                <AddIcon/>
              </Fab>
            </Link>
          </div>
          <div>
            <Jobs/>
            <BookingList refresh={this.doSearch}/>
          </div>
        </div>
    );
  }
}



export default withStyles(styles)(inject('appState')(observer(Bookings)));