
const StatusColour = (status) =>{

    let colour = '#1565C0';

    switch(status) {
        case 'CONFIRMED':
            colour = '#1565C0'
            break;
        case 'PENDING':
            colour = '#FFC107'
            break;
        case 'NO-SHOW':
            colour = '#BF360C'
            break;
        case 'CANCELLED':
            colour = '#757575'
            break;
        case 'ALLOCATED':
            colour = '#009688'
            break;
        default:
            colour = '#1565C0'
    }

    return(colour);
}

export default StatusColour;