import React, { Component } from 'react';
import { observer, inject } from 'mobx-react'; 
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
//import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import VehiclesDetails from './VehiclesDetails';
import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
import Docs from './Docs';
import DocsMonitor from './DocsMonitor';

const styles = theme => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  paper: {
    flexGrow: 1, 
    padding: theme.spacing(2),
    display: 'flex',
    flexFlow: 'column',
  },
  search: {
    flexGrow: 1,
  },
  list: {
    flexGrow: 1,
    marginTop: theme.spacing(2)
  },
});

class Vehicles extends Component {
  state={
    items: [],
    search: '',
    currentVehicle: {},
  }

  componentDidMount = () => {
    this.getData();
  }

  getData = () => {
    fetch(this.props.appState.appRoutine.apiUrl + 'vehicles/list', 
      {
      method: 'GET',
      headers: {'Content-Type': 'application/json'},
      })
      .then(res => res.json())
      .then(
        (res) => {
          if(res.result.length > 0){
            this.setState({items: res.result});
          } else {
            this.setState({
              loginFail: true,});
          }
        },
        (error) => {
          alert(error);
        }
      )
  }

  handleSearch = event => {
    this.setState({
      search: event.target.value,
    });
  }

  handleClick = event => {
    console.log(event.target.name);
  }

  handleNew = () => {
    const emptyVehicle = {
      ID: 0,
      MAKE: '',
      MODEL: '',
      REG: '',
      PHV: '', 
      KEEPER: '',
      STATUS: 'Active',
      NOTES: '',
    }
    this.props.appState.vehicle = emptyVehicle;
  }


getListColor = () => {
  return 'textPrimary';
}

  render() {
    const {classes} = this.props;

    return (
        <Grid container className={classes.root} spacing={3}>
          <Grid item xs={12} sm={7} md={8}>
            {this.props.appState.vehicle.ID !== undefined ? 
            <div>
              <VehiclesDetails currentVehicle={this.state.currentVehicle} reload={this.getData}/>
              <Docs 
                id={this.props.appState.vehicle.ID}
                assignement='vehicle'
              />
            </div>
            :
            <DocsMonitor assignement='vehicle' />
          }
          </Grid>
          <Grid item xs={12} sm={5} md={4}>
            <Paper className={classes.paper}>
              <Grid container spacing={2} >
                <Grid item xs={9} sm={8} md={8} lg={8} xl={10} style={{display: 'flex'}}>
                  <TextField
                   style={{flexGrow: 1}}
                    className={classes.search}
                    id="search"
                    onChange={this.handleSearch}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start" >
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                  </Grid>
                  <Grid item xs={3} sm={4} md={4} lg={4} xl={2} style={{textAlign: 'center'}}>
                    <IconButton color="primary" aria-label="Add New" component="span" onClick={this.handleNew}>
                      <AddIcon />
                    </IconButton>
                  </Grid>
              </Grid>
            {this.state.items.length > 0 && 
              <List component="nav" className={classes.list}>
                {this.state.items
                .filter(item => {
                  return item.REG.toLowerCase().match(this.state.search.toLowerCase()) || 
                          item.KEEPER.toLowerCase().match(this.state.search.toLowerCase()) ||
                          item.MAKE.toLowerCase().match(this.state.search.toLowerCase()) ||
                          item.MODEL.toLowerCase().match(this.state.search.toLowerCase())

                })
                .sort((a, b)=>{
                  var nameA=a.REG.toLowerCase(), nameB=b.REG.toLowerCase()
                  if (nameA < nameB) //sort string ascending
                      return -1 
                  if (nameA > nameB)
                      return 1
                  return 0 //default return value (no sorting)
                })
                .sort((a, b)=>{
                  var nameA=a.STATUS.toLowerCase(), nameB=b.STATUS.toLowerCase()
                  if (nameA < nameB) //sort string ascending
                      return -1 
                  if (nameA > nameB)
                      return 1
                  return 0 //default return value (no sorting)
                })
                .map((item, index) => 
                  <ListItem button key={index} onClick={() => {this.props.appState.vehicle = item}}>
                    <ListItemText primary={
                      <React.Fragment>
                        <Typography
                          component="span"
                          variant="body1"
                          className={classes.inline}
                          color={item.STATUS==='Active'? 'textPrimary': 'textSecondary'}
                        >
                          {item.REG + ' - ' + item.MAKE + ' ' + item.MODEL}
                        </Typography>
                        </React.Fragment>
                        }
                        secondary={item.KEEPER}
                      />
                  </ListItem>
                )}
              </List>
              }
            </Paper>
          </Grid>
        </Grid>
    );
  }
}



export default withStyles(styles)(inject('appState')(observer(Vehicles)));