import React, { Component } from 'react';
import { observer, inject } from 'mobx-react'; 
import { withStyles } from '@material-ui/core/styles';
//import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Upload from './Upload';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import DocIcon from '@material-ui/icons/Description';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { format } from 'date-fns';


const styles = theme => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
      },
    header: {
        marginTop: 40,
        marginBottom: 20,
    },
    paper: {
      padding: theme.spacing(2),
      marginTop: 30,
    }, 
    icon: {
      fontSize: 40,
      padding: theme.spacing(2),
    },
});

class Docs extends Component {
  state={
    items: [],
    askDeldlgOpen: false,
    selectedItem: {
      id: '',
      file: '',
    }
  }

  componentDidMount = () => {
    this.getData();
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.id !== prevProps.id) {
      this.getData();
    }
  }

  getData = () => {

    const qry = {
      'id': this.props.id,
      'assignement': this.props.assignement,
    }

    fetch(this.props.appState.appRoutine.apiUrl + 'documents/list', 
      {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(qry),
      })
      .then(res => res.json())
      .then(
        (res) => {
          this.setState({items:[]});
          if(res.result.length > 0){
            this.setState({items: res.result});
          } else if(res.status === 'err'){
            console.log(res)
          } 
        },
        (error) => {
          alert(error);
        }
      )
  }


  doDelete = (file, id) => {
    this.setState({
      askDeldlgOpen: false
    });

    const qry = {
      'id': this.state.selectedItem.id,
      'file': this.state.selectedItem.file,
    }
    this.props.appState.appRoutine.loading = true;

    fetch(this.props.appState.appRoutine.apiUrl + 'documents/delete', 
      {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(qry),
      })
      .then(res => res.json())
      .then(
        (result) => {
            this.props.appState.appRoutine.loading = false;
          if(result.status === 'err'){
            alert(result)
          };
          this.setState({items: []})
          this.getData()
        },
        (error) => {
            this.props.appState.appRoutine.loading = false;
            alert(error)
        }
      )
  }




  render() {
    const {classes} = this.props;
    
    return (
      <div className={classes.root}>
        <Typography variant="h6" gutterBottom className={classes.header} >Attached Documents</Typography>
        
        {this.state.items.map((item, index) => {
          const daysLeft = Math.ceil((new Date (item.DOCEXPIRY) - Date.now()) / (1000*60*60*24));
          let color = 'Teal'; 
          if(daysLeft > 0 && daysLeft < 10){color = 'DarkOrange'}
          else if(daysLeft < 1){color = 'Brown'}

          return (
          <div key={index}>
          <Paper className={classes.paper} >
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={4} >
                <DocIcon className={classes.icon} style={{color: color}}/>
              </Grid>
              <Grid item xs={12} sm={12} md={8} container pacing={1} >
                <Grid item xs={2} sm={2} md={12} />
                <Grid item xs={4} sm={4} md={4} style={{paddingTop: 15, textAlign: 'left',}}>
                  <Typography variant='body2'>Document:</Typography>
                  <Typography variant='body2'>Expiry Date:</Typography>
                  <Typography variant='body2'>Current validity:</Typography>
                </Grid>
                <Grid item xs={4} sm={4} md={5} style={{paddingTop: 15, textAlign: 'left',}}>
                  <Typography variant='subtitle2'>
                    <a href={window.location.origin + '/files/docs/' + item.FILENAME} target="_blank" rel="noopener noreferrer">{item.DOCNAME}</a>
                    </Typography>
                  <Typography variant='subtitle2'>{format(new Date (item.DOCEXPIRY), 'dd-MMM-yyyy')}</Typography>
                  <Typography variant='subtitle2'>{daysLeft + ' days'}</Typography>
                </Grid>
                <Grid item xs={2} sm={2} md={12} />
                <Grid item xs={12} style={{paddingTop: 15, textAlign: 'right',}}>
                    <IconButton color="primary" aria-label="Delete" component="span" 
                                onClick={()=>{
                                  this.setState({
                                    askDeldlgOpen: true,
                                    selectedItem: {
                                      id: item.ID,
                                      file: item.FILENAME,
                                    }
                                  })
                                  }}>
                        <DeleteIcon />
                    </IconButton>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </div>
          )}
        )}


        <Dialog
            open={this.state.askDeldlgOpen}
            onClose={()=>this.setState({askDeldlgOpen: false})}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Delete?"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Please confirm you really want to delete the file
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={()=>this.setState({askDeldlgOpen: false})} color="primary">
                Cancel
              </Button>
              <Button onClick={()=>this.doDelete()} color="primary" autoFocus>
                Confirm
              </Button>
            </DialogActions>
          </Dialog>

        {this.props.id ? 
        <Upload
          type="doc"
          assignement={this.props.assignement}
          title="Upload new document"
          id={this.props.id}
          reload={this.getData}
        />
        :
        <Upload
          type="doc"
          assignement={this.props.assignement}
          title="Upload new document"
          disabled
        />
        }
      </div>
    );
  }
}

export default withStyles(styles)(inject('appState')(observer(Docs)));