import React, { Component } from 'react';
import { observer, inject } from 'mobx-react'; 
import { withStyles } from '@material-ui/core/styles';
//import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import VehiclesIcon from '@material-ui/icons/DirectionsCar';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/ExitToApp';
import SaveIcon from '@material-ui/icons/Save';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';


const styles = theme => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(2),
      },
    paper: {
        flexGrow: 1, 
        padding: theme.spacing(2),
        display: 'flex',
        flexFlow: 'column',
      },
      icon: {
        fontSize: 100,
        color: '#607d8b',
        marginTop: theme.spacing(2),
        padding: theme.spacing(2),
      },
      leftCol:{
        textAlign: 'center',
      },
      rightCol: {
        marginTop: theme.spacing(2),
        display: 'flex',
        flexFlow: 'column',
        padding: theme.spacing(2)
      },
      input: {
          flexGrow: 1,
          marginTop: theme.spacing(2)
      },
      buttons: {
        marginTop: theme.spacing(8),
        flexGrow: 1,
        display: 'flex',
        flexFlow: 'row',
        justifyContent: 'space-between'
      }
});

class VehiclesDetails extends Component {
    state = {
        vehicle: {},
        askDeldlgOpen: false,
    }

    handleSwitchAvailable = event => {
        let status = 'Active';
        if(event.target.checked===false){
            status = 'Inactive';
        } 
        this.props.appState.vehicle.STATUS = status;
    }

    communicate = (qry, route) =>{
        this.props.appState.appRoutine.loading = true;

        fetch(this.props.appState.appRoutine.apiUrl + route, 
          {
          method: 'POST', // or 'PUT'
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify(qry),
          })
          .then(res => res.json())
          .then(
            (result) => {
                this.props.appState.appRoutine.loading = false;
              if(result.status === 'err'){
                alert(result)
              };
              if(result.result.insertId>0){
                this.props.appState.vehicle.ID = result.result.insertId;
              }
              this.props.appState.appRoutine.msg = {
                text: 'Saved',
                open: true,
                type: 'success',
              }
              this.props.reload();
            },
            (error) => {
                this.props.appState.appRoutine.loading = false;
                alert(error)
            }
          )
    }

    handleUpdate = () => {
         const qry = this.props.appState.vehicle;
         let route =  'vehicles/update';
         if(this.props.appState.vehicle.ID === 0){
            route =  'vehicles/insert'
         }
         this.communicate(qry, route);
      }
      handleClose = () => {
        this.props.appState.vehicle = {};
        
     }

    doDelete = () => {
        const qry = {
            'ID': this.props.appState.vehicle.ID
        }
        const route = 'vehicles/delete';
        this.communicate(qry, route);
        this.props.appState.vehicle = {};
    }

  render() {
    const {classes, appState} = this.props;
    

    return (
              <div>
                <Paper className={classes.paper}>
                    <Grid container className={classes.root} spacing={3}>
                        <Grid item xs={12} sm={12} md={4} className={classes.leftCol} >
                                <VehiclesIcon className={classes.icon}/>
                        </Grid>
                        <Grid item xs={12} sm={12} md={8} className={classes.rightCol}>
                            <TextField 
                                label="Make" 
                                variant="outlined" 
                                className={classes.input} 
                                value={appState.vehicle.MAKE} 
                                onChange={(event)=>{appState.vehicle.MAKE = event.target.value}}
                                />
                            <TextField label="Model" 
                                variant="outlined" 
                                className={classes.input} 
                                value={appState.vehicle.MODEL}
                                onChange={(event)=>{appState.vehicle.MODEL = event.target.value}}
                                />
                             <Grid container spacing={3}>
                                <Grid item xs={12} sm={12} md={6}  style={{display: 'flex'}}>
                                    <TextField 
                                        label="Reg" 
                                        variant="outlined" 
                                        className={classes.input} 
                                        value={appState.vehicle.REG}
                                        onChange={(event)=>{appState.vehicle.REG = event.target.value}}
                                        />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} style={{display: 'flex'}}>
                                    <TextField 
                                        label="PHV" 
                                        variant="outlined" 
                                        className={classes.input} 
                                        value={appState.vehicle.PHV}
                                        onChange={(event)=>{appState.vehicle.PHV = event.target.value}}
                                        />
                                </Grid> 
                            </Grid>                           
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={12} md={6}  style={{display: 'flex'}}>
                                    <TextField 
                                        label="Keeper" 
                                        variant="outlined" 
                                        className={classes.input} 
                                        value={appState.vehicle.KEEPER}
                                        onChange={(event)=>{appState.vehicle.KEEPER = event.target.value}}
                                        />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} >
                                    <FormControlLabel
                                        style={{padding: 8, marginLeft: 5}}
                                        className={classes.input}
                                        control={
                                        <Switch
                                            checked={appState.vehicle.STATUS==='Active'? true : false}
                                            onChange={this.handleSwitchAvailable}
                                            value={appState.vehicle.STATUS}
                                            color="primary"
                                        />
                                        }
                                        label="Active"
                                    />
                                </Grid>
                            </Grid>
                            <TextField 
                                label="Notes" 
                                variant="outlined" 
                                multiline rows='3' 
                                className={classes.input}
                                value={appState.vehicle.NOTES}
                                onChange={(event)=>{appState.vehicle.NOTES = event.target.value}}
                                />  

                            <div className={classes.buttons}>
                              <div>
                                <IconButton color="primary" aria-label="Delete" style={{margin: 10}} component="span" onClick={()=>this.setState({askDeldlgOpen: true})}>
                                  <DeleteIcon />
                                </IconButton>
                                </div>
                                <div>
                                 <Button variant="contained" style={{margin: 10}} onClick={this.handleClose}>
                                    <CloseIcon />
                                   </Button>    
                                 <Button variant="contained" color="primary" style={{margin: 10, float: 'right'}} onClick={this.handleUpdate}>
                                    <SaveIcon />
                                   </Button>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </Paper>

                <Dialog
                    open={this.state.askDeldlgOpen}
                    onClose={()=>this.setState({askDeldlgOpen: false})}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Delete?"}</DialogTitle>
                    <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Please confirm you really want to delete.
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={()=>this.setState({askDeldlgOpen: false})} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={this.doDelete} color="primary" autoFocus>
                        Confirm
                    </Button>
                    </DialogActions>
                </Dialog>
            </div>
    );
  }
}



export default withStyles(styles)(inject('appState')(observer(VehiclesDetails)));