import React from 'react';
import { observer, inject } from 'mobx-react'; 
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import IconAccessTime from '@material-ui/icons/AccessTime';
import Button from '@material-ui/core/Button';
import Links from '@material-ui/core/Link';
import {BrowserRouter as Router, Link} from 'react-router-dom';
//import ViewBooking from './ViewBooking'; 
import StatusColour from './StatusColour';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';



const styles = theme => ({
  title: {
    marginTop: 2,
  }
});

function Transition(props) {
  return <Slide direction="up" {...props} />;
}


class BookingListItem extends React.Component {
  state = {
    open: this.props.expanded,
    openDriver: false,
    openCar: false,
    selectDriver: false,
    drivers: [],
  };

  

  componentWillReceiveProps(nextProps){
    if(nextProps.exponded!==this.state.open){
      //Perform some operation
      this.setState({open: nextProps.expanded });
    }
  }

  componentDidMount(){
    this.getDrivers();
  }

  handleClick = () => {
    this.setState(state => ({ open: !state.open }));
  };

  getDrivers = () => {
    fetch(this.props.appState.appRoutine.apiUrl + 'drivers/list', 
      {
      method: 'GET',
      headers: {'Content-Type': 'application/json'},
      })
      .then(res => res.json())
      .then(
        (res) => {
          if(res.result.length > 0){
            this.setState({drivers: res.result.filter(ob=>ob.STATUS === 'Active')});
          } 
        },
        (error) => {
          alert(error);
        }
      )
  }

  selectDriver=(driver)=>{
    this.setState({selectDriver: false})
    this.doUpdate(driver);
    //this.props.item.drivername = driver.NAME;
    //this.props.item.DRIVER = driver.SURNAME;
    //this.props.item.CARREG = driver.VEHICLE;
    //this.props.item.driverphoto = driver.PHOTO;
    //this.props.item.driverphone = driver.PHONE;
    //this.props.item.driverphv = driver.PHV;
    //this.props.item.STATUS = 'ALLOCATED';
    
  }
  

  doUpdate=(driver)=>{
    let theTransfer = {
      ID: this.props.item.ID,
      DRIVER: '',
      SUPPLIER: 'Deluxemotion',
      CARREG: '',
      STATUS: 'CONFIRMED',
    }
    if(driver){
    theTransfer = {
      ID: this.props.item.ID,
      DRIVER: driver.SURNAME,
      SUPPLIER: 'Deluxemotion',
      CARREG: driver.VEHICLE,
      STATUS: 'ALLOCATED',
    }
  }

    fetch(this.props.appState.appRoutine.apiUrl + 'edittransfer/jobs', 
      {
      method: 'POST', // or 'PUT'
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(theTransfer),
      })
      .then(res => res.json())
      .then(
        (result) => {
            this.props.appState.appRoutine.loading = false;
          if(result.status === 'err'){
            alert(result)
            console.log(result)
          }else{
            this.props.appState.appRoutine.msg = {
              text: 'Saved',
              open: true,
              type: 'success',
            };
            this.props.refresh();
          }
        },
        (error) => {
            this.props.appState.appRoutine.loading = false;
            alert(error)
            console.log(error)
        }
      )
  }

  render() {
    const { item, appState, classes} = this.props;

    return (
      <div>
          <ListItem button onClick={this.handleClick}>
               
               <Grid container spacing={3} >
                <Grid item xs={12} sm={12} md={2} lg={2} xl={2} style={{textAlign: 'center',}}>
                      <Typography variant="subtitle2" >{item.REF}</Typography>
                      <div style={{color: 'white', fontSize: 10, padding: 6, width: '90%', textAlign: 'center', borderRadius: 2, backgroundColor: StatusColour(item.STATUS)}}>
                        {item.STATUS}
                      </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={2} lg={1} xl={1}>
                    <Typography variant="caption">Time:</Typography>
                    <div style={{textAlign: 'center', display: 'flex'}}>
                      <IconAccessTime style={{fontSize: 20, color: "gray", marginTop: 4,}}/>
                      <Typography variant="subtitle1">{item.TIME}</Typography>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12}  md={3} lg={4} xl={4}>
                    <Typography variant="caption">
                      Destination:
                    </Typography>
                    <Typography variant="subtitle2">
                      {item.FROMTITLE} --> {item.TOTITLE}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                    <Typography variant="caption">
                      Name/Pax:
                    </Typography>
                    <Typography variant="subtitle2">{item.NAME} X {item.PAX}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                    <Typography variant="caption">
                      Car class:
                    </Typography>
                    <Typography variant="subtitle2">{item.CARCLASS}</Typography>
                  </Grid>
               </Grid>
               {this.state.open ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          
          <Collapse in={this.state.open} timeout="auto" unmountOnExit>
            <List component="div" style={{paddingBottom: 40}}>
              <ListItem>
                <Grid container >
                  <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                    <Router>
                      {//<Route path="/viewBooking" component={ViewBooking}/>
                      }
                    </Router>
                    <div >
                      <Link to={{ pathname: '/viewBooking', state: {ref: item.REF} }} onClick={()=>appState.changePage("View Booking")}>
                        <Button size="small" color="primary" style={{width: '85%'}}>VIEW</Button>
                      </Link>
                    </div>
                    <div >
                      <Link to={{ pathname: '/editBooking', state: {id: item.ID} }} onClick={()=>appState.changePage("Edit Booking")}>
                        <Button size="small" color="secondary" style={{width: '85%'}} >EDIT</Button>
                      </Link>
                    </div>
                    <Button size="small" color="primary" style={{width: '85%'}} onClick={()=>{this.setState({selectDriver: true})}}>DRIVER</Button>
                  </Grid>
                  <Grid item xs={12} sm={12} md={2} lg={1} xl={1}>
                    <Typography variant="subtitle2" style={{marginLeft: 8}}>£    {item.PRICE}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={3} lg={4} xl={4}>
                    <Typography variant="caption" >Pick-up:</Typography>
                    <Typography variant="body2">{item.FROMDETAILS}</Typography>
                    <Typography variant="caption" style={{marginTop: 5}}>Service to:</Typography>
                    <Typography variant="body2">{item.TODETAILS}</Typography>
                    <Typography variant="caption" style={{marginTop: 5}}>Notes:</Typography>
                    <Typography variant="body2">{item.NOTESS}</Typography>
                  </Grid> 
                  <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                  {item.DRIVER && 
                    <div>
                    <Typography variant="caption" >Agent:</Typography>
                    <Typography variant="body2">{item.AGENT}</Typography>
                    <Typography variant="caption" style={{marginTop: 5}}>Agent's Ref:</Typography>
                    <Typography variant="body2">{item.AGENTREF}</Typography>
                    </div>}
                    <Typography variant="caption" style={{marginTop: 5}}>Supplier:</Typography>
                    <Typography variant="body2">{item.SUPPLIER}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>                    
                    {item.DRIVER && 
                    <div>
                    <Typography variant="caption" style={{marginTop: 5}}>Driver:</Typography>
                    <Typography variant="subtitle2">
                      <Links component="button" variant="subtitle2" onClick={()=>{this.setState({openDriver: true})}}>{item.drivername + ' ' +  item.DRIVER}</Links>
                    </Typography>
                    <Typography variant="caption" style={{marginTop: 5}}>Vehicle:</Typography>
                    <Typography variant="subtitle2">
                      <Links component="button" variant="subtitle2" onClick={()=>{this.setState({openCar: true})}}>{item.CARREG}</Links>
                    </Typography>
                    </div>
                    }
                  </Grid>
                </Grid>
              </ListItem>
            </List>
          </Collapse>
          

          <Dialog
            open={this.state.openDriver}
            TransitionComponent={Transition}
            keepMounted
            onClose={()=>{this.setState({openDriver: false})}}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            style={{minWidth: 200}}
          >
            <DialogTitle id="alert-dialog-slide-title">{"Driver Details"}</DialogTitle>
            <DialogContent style={{textAlign: 'center'}}>
              <DialogContentText id="alert-dialog-slide-description">
                <img 
                    src={window.location.origin + '/files/photos/' + item.driverphoto}
                    alt={this.props.appState.driver.SURNAME}
                    style={{
                        marginTop: 20,
                        width: 240,
                        borderRadius: 5,
                      }}                
                  />
              </DialogContentText>
              <DialogContentText id="alert-dialog-slide-description" style={{minWidth: 300}}>
                  <Typography variant="body2" >Name / Surname:</Typography>
                  <Typography variant="h6">{item.drivername} {item.DRIVER}</Typography>
              </DialogContentText>
              <DialogContentText id="alert-dialog-slide-description">
                  <Typography variant="body2" >Phone:</Typography>
                  <Typography variant="h6">{item.driverphone}</Typography>
              </DialogContentText>
              <DialogContentText id="alert-dialog-slide-description">
                  <Typography variant="body2" >PCO license:</Typography>
                  <Typography variant="h6">{item.driverphv}</Typography>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={()=>{this.setState({openDriver: false})}} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>     


          <Dialog
            open={this.state.openCar}
            TransitionComponent={Transition}
            keepMounted
            onClose={()=>{this.setState({openCar: false})}}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            
          >
            <DialogTitle id="alert-dialog-slide-title">{"Vehicle Details"}</DialogTitle>
            <DialogContent style={{textAlign: 'center'}}>
              <DialogContentText id="alert-dialog-slide-description" style={{minWidth: 300}}>
                  <Typography variant="body2" >Vehicle:</Typography>
                  <Typography variant="h6">{item.CARCLASS}</Typography>
                  <Typography variant="body2" >Reg. no:</Typography>
                  <Typography variant="h6">{item.CARREG}</Typography>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={()=>{this.setState({openCar: false})}} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>         




          <Dialog
            open={this.state.selectDriver}
            TransitionComponent={Transition}
            keepMounted
            //maxWidth="xs"
            fullWidth
            onClose={()=>{this.setState({driverOpen: false})}}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            
          >
            <DialogTitle id="alert-dialog-slide-title">{"Select Driver"}</DialogTitle>
            <DialogContent dividers scroll style={{textAlign: 'center'}}>
              <List component="nav" className={classes.list}>
              {
                this.state.drivers.map((item, index)=>
                <ListItem button key={index} onClick={() => {this.selectDriver(item)}}>
                  <ListItemText primary={
                    <React.Fragment>
                      <Typography
                        component="span"
                        variant="body1"
                        className={classes.inline}
                        color={item.STATUS==='Active'? 'textPrimary': 'textSecondary'}
                      >
                        {item.NAME + ' ' + item.SURNAME}
                      </Typography>
                      </React.Fragment>
                      }
                      secondary={item.VEHICLE}
                    />
                </ListItem>
                )
              }
              </List>
            </DialogContent>
            <DialogActions>
              <Button onClick={()=>{this.setState({selectDriver: false}); this.selectDriver()}} color="primary">
                Clear
              </Button>
              <Button onClick={()=>{this.setState({selectDriver: false})}} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>  
      </div>
    );
  }
}

BookingListItem.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(inject('appState')(observer(BookingListItem)));