import React, { Component } from 'react';
import { observer, inject } from 'mobx-react'
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';


const styles = theme => ({
  paper: {
      flexGrow: 1, 
      margin: theme.spacing(3),
      padding: theme.spacing(2),
      display: 'flex',
      flexFlow: 'column',
      textAlign: 'center',
    },
});

class About extends Component {

  render() {
    const {classes} = this.props;
    return (
      <Paper className={classes.paper}>
        <Typography variant='subtitle2'>
              Created by Eugene Silovs for DeluxeMotion
        </Typography>
      </Paper>
    );
  }
}

export default withStyles(styles)(inject('appState')(observer(About)));