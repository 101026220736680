import React, { Component } from 'react';
import { observer, inject } from 'mobx-react'
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputAdornment from '@material-ui/core/InputAdornment';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import DateFnsUtils from '@date-io/date-fns';
import { format } from 'date-fns';
import gbLocale from "date-fns/locale/en-GB";
import {MuiPickersUtilsProvider, KeyboardDatePicker, TimePicker, } from "@material-ui/pickers";
import { isUndefined } from 'util';
import {Link} from 'react-router-dom';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

import PeopleIcon from '@material-ui/icons/People';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import MapIcon from '@material-ui/icons/Map';
import NoteIcon from '@material-ui/icons/Note';
import AccessibilityIcon from '@material-ui/icons/Accessibility';
import ListIcon from '@material-ui/icons/List';
import CopyIcon from '@material-ui/icons/FileCopy';
import UnlinkIcon from '@material-ui/icons/LinkOff';
//import SearchIcon from '@material-ui/icons/Search';


const styles = theme => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  paper: {
    flexGrow: 1, 
    padding: theme.spacing(3),
    display: 'flex',
    flexFlow: 'column',
    marginTop: 15,
  },
  titleIcon: {
    fontSize: 40,
    color: '#607d8b',
    marginRight: 20,
  },
  title: {
    display: 'flex',
    marginBottom: theme.spacing(6),
  },
  titleText: {
    marginTop: 8,
    color: '#607d8b',
  },
  textField: {
    marginBottom: theme.spacing(2)
  },
  list: {
    flexGrow: 1,
  },
});


function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class EditBooking extends Component {
  state={
      ID: '',
      REF: '',
      DATE: new Date(),
      TIME: '12:00',
      NAME: '',
      PAX: 1,
      FROMTITLE: '',
      TOTITLE: '',
      FROMDETAILS: '',
      TODETAILS: '',
      AGENT: '',
      AGENTREF: '',
      DRIVER: '',
      SUPPLIER: 'Deluxemotion',
      BOOKINGDATE: new Date(),
      CARCLASS: 'MPV LUX',
      CARREG: '',
      PRICE: 0,
      STATUS: 'CONFIRMED',
      NOTES: '',
      BOOKEDBY: 'Igor Boiko',
      ALLOCATEDBY: '',
      suppliers: [],
      drivers: [],
      cars: [],
      supplierOpen: false,
      driverOpen: false,
      carOpen: false,
  }


  componentDidMount=()=>{
    if(!isUndefined(this.props.location.state)){
        this.doSearch(this.props.location.state.id);
      }
    if(!isUndefined(this.props.location.state)){
      this.setState({REF: this.props.location.state.ref})
    }
    this.getSuppliers();
    this.getDrivers();
    this.getCars();
  }


  handleChange = event => {
    const id = event.target.id;
    this.setState({
      [id]: event.target.value,
    })
  }



doSearch = (id) => {
    fetch(this.props.appState.appRoutine.apiUrl + 'edittransfer/' + id, 
      {
      method: 'GET',
      headers: {'Content-Type': 'application/json'},
      })
      .then(res => res.json())
      .then(
        (res) => {
          if(res.result.length > 0){
            this.setState(res.result[0]);
          } 
        },
        (error) => {
          alert(error);
        }
      )
  }
  getSuppliers = () => {
    fetch(this.props.appState.appRoutine.apiUrl + 'suppliers/listactive', 
      {
      method: 'GET',
      headers: {'Content-Type': 'application/json'},
      })
      .then(res => res.json())
      .then(
        (res) => {
          if(res.result.length > 0){
            this.setState({suppliers: res.result});
          } 
        },
        (error) => {
          alert(error);
        }
      )
  }
  getDrivers = () => {
    fetch(this.props.appState.appRoutine.apiUrl + 'drivers/listactive', 
      {
      method: 'GET',
      headers: {'Content-Type': 'application/json'},
      })
      .then(res => res.json())
      .then(
        (res) => {
          if(res.result.length > 0){
            this.setState({drivers: res.result});
          } 
        },
        (error) => {
          alert(error);
        }
      )
  }
  getCars = () => {
    fetch(this.props.appState.appRoutine.apiUrl + 'vehicles/listactive', 
      {
      method: 'GET',
      headers: {'Content-Type': 'application/json'},
      })
      .then(res => res.json())
      .then(
        (res) => {
          if(res.result.length > 0){
            this.setState({cars: res.result});
          } 
        },
        (error) => {
          alert(error);
        }
      )
  }

  unlinkRef=()=>{
    if(this.state.ID!==''){
      const newRef = 'DLX' + (10000 + parseInt(this.state.ID));
      this.setState({
        REF: newRef,
      })
    }
  }

  doCopy =()=>{
    this.setState({ID: ''});
  }

  handleSave=()=>{
    if(this.state.ID==='' && this.state.REF===''){
      fetch(this.props.appState.appRoutine.apiUrl + 'edittransfer/newref')
      .then(res => res.json())
      .then(
        (result) => {
          const newRef = result.result;
          this.doUpdate('insert', newRef);
        },
        (error) => {
            console.log(error)
        }
      )
    }else if(this.state.ID===''){
      const oldRef = this.state.REF;
      this.doUpdate('insert', oldRef);
    }else{
      const oldRef = this.state.REF;
      this.doUpdate('update', oldRef);
    }
  }

  doUpdate=(route, theRef)=>{

    const theTransfer = {
      ID: this.state.ID,
      REF: theRef,
      DATE: format(new Date(this.state.DATE), 'dd/MM/yyyy'),
      TIME: this.state.TIME,
      NAME: this.state.NAME,
      PAX: this.state.PAX,
      FROMTITLE: this.state.FROMTITLE,
      TOTITLE: this.state.TOTITLE,
      FROMDETAILS: this.state.FROMDETAILS,
      TODETAILS: this.state.TODETAILS,
      AGENT: this.state.AGENT,
      AGENTREF: this.state.AGENTREF,
      DRIVER: this.state.DRIVER,
      SUPPLIER: this.state.SUPPLIER,
      CARCLASS: this.state.CARCLASS,
      CARREG: this.state.CARREG,
      PRICE: this.state.PRICE,
      STATUS: this.state.STATUS,
      NOTES: this.state.NOTES,
      BOOKEDBY: this.state.BOOKEDBY,
      ALLOCATEDBY: this.state.ALLOCATEDBY,
      BOOKINGDATE: format(new Date(this.state.BOOKINGDATE), 'dd/MM/yyyy'),
    }

    fetch(this.props.appState.appRoutine.apiUrl + 'edittransfer/' + route, 
      {
      method: 'POST', // or 'PUT'
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(theTransfer),
      })
      .then(res => res.json())
      .then(
        (result) => {
            this.props.appState.appRoutine.loading = false;
          if(result.status === 'err'){
            alert(result)
            console.log(result)
          }else{
            this.setState({REF: theRef});
            this.props.appState.appRoutine.msg = {
              text: 'Saved',
              open: true,
              type: 'success',
            }
          }
        },
        (error) => {
            this.props.appState.appRoutine.loading = false;
            alert(error)
            console.log(error)
        }
      )
  }



  render() {
    const {classes, appState} = this.props;
    const paxNr = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,25,26,27,28,29,30,31,32,33,34,35,36,37,28,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53];

    return (
      <div>
      <Paper className={classes.root} elevation={1} style={{minHeight: 55, backgroundColor: '#ECEFF1'}}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} style={{display: 'flex'}}>
            <Button style={{margin: 10}} onClick={this.unlinkRef} >
              <UnlinkIcon style={{color: '#607d8b'}}/>
            </Button>
            <TextField id="standard-basic" 
              label="Ref" 
              style={{marginBottom: 15}}
              value={this.state.REF}
              onChange={(event)=>this.setState({REF: event.target.value})}
            />{this.state.REF && 
            <Link to={{ pathname: '/viewBooking', state: {ref: this.state.REF} }} onClick={()=>appState.changePage("View Booking")}>
              <Button style={{margin: 10}} variant="outlined">VIEW</Button>
            </Link>}
          </Grid>

          <Grid item xs={12} md={6} style={{display: 'flex'}}>
            <Button style={{margin: 8}} onClick={this.doCopy}>
              <CopyIcon style={{color: '#607d8b'}}/>
            </Button>
            <FormControl style={{flexGrow: 1, marginBottom: 15}}>
              <InputLabel id="demo-simple-select-label">Status</InputLabel>
              <Select
                 labelWidth={125}
                id="STATUS"
                
                value={this.state.STATUS}
                onChange={(event)=>this.setState({STATUS: event.target.value})}
                >
                <MenuItem value="CONFIRMED">CONFIRMED</MenuItem>
                <MenuItem value="PENDING">PENDING</MenuItem>
                <MenuItem value="ALLOCATED">ALLOCATED</MenuItem>
                <MenuItem value="NO-SHOW">NO-SHOW</MenuItem>
                <MenuItem value="CANCELLED">CANCELLED</MenuItem>
              </Select>
            </FormControl>
            <Button variant="contained" color="primary" style={{margin: 10}} onClick={this.handleSave}>SAVE</Button>
          </Grid>
        </Grid>
      </Paper>

      <div className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={5}>
            
            <Paper className={classes.paper}>
              <div className={classes.title}>
                <PeopleIcon className={classes.titleIcon}/>
                <Typography variant="h6" className={classes.titleText}>CLIENTS</Typography>
              </div>
              <div style={{display: 'flex'}}>
                <TextField 
                  label="Name" 
                  variant="outlined" 
                  style={{width: 80, flexGrow: 1}}
                  className={classes.textField}
                  value={this.state.NAME}
                  onChange={(event)=>this.setState({NAME: event.target.value})}
                  />
                
                <FormControl variant="outlined" className={classes.textField}>
                  <Select
                    style={{marginLeft: 10, width: 110}}
                    id="PAX"
                    value={this.state.PAX}
                    onChange={(event)=>this.setState({PAX: event.target.value})}
                    startAdornment={<InputAdornment position="start"><AccessibilityIcon style={{fontSize: 22, marginLeft: -5, color: '#607d8b'}}/></InputAdornment>}
                  >{paxNr.map((pax, index)=>
                      <MenuItem key={index} value={pax}>{pax}</MenuItem>
                    )
                  }
                  </Select>
                </FormControl>
              </div>
              <TextField 
                label="Agent" 
                variant="outlined" 
                className={classes.textField}
                value={this.state.AGENT}
                onChange={(event)=>this.setState({AGENT: event.target.value})}
                />
                <TextField 
                label="Agent's ref" 
                variant="outlined" 
                className={classes.textField}
                value={this.state.AGENTREF}
                onChange={(event)=>this.setState({AGENTREF: event.target.value})}
                />
                <TextField 
                  label="Price" 
                  variant="outlined" 
                  className={classes.textField}
                  type='number'
                  value={this.state.PRICE}
                  onChange={(event)=>this.setState({PRICE: event.target.value})}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">£</InputAdornment>,
                  }}
                />
            </Paper>
            
            <Paper className={classes.paper}>
              <div className={classes.title}>
                <DriveEtaIcon className={classes.titleIcon}/>
                <Typography variant="h6" className={classes.titleText}>VEHICLE</Typography>
              </div>
              <FormControl variant="outlined" className={classes.textField}>
                  <InputLabel id="demo-simple-select-label">Vehicle Category</InputLabel>
                  <Select
                    labelWidth={125}
                    id="CARCLASS"
                    value={this.state.CARCLASS}
                    onChange={(event)=>this.setState({CARCLASS: event.target.value})}
                  >
                    <MenuItem value="STANDARD">STANDARD</MenuItem>
                    <MenuItem value="EXEC">EXEC</MenuItem>
                    <MenuItem value="LUX">LUX</MenuItem>
                    <MenuItem value="MPV">MPV</MenuItem>
                    <MenuItem value="MPV LUX">MPV LUX</MenuItem>
                    <MenuItem value="BUS">BUS</MenuItem>
                  </Select>
                </FormControl>
                
                <div style={{display: 'flex'}}>
                  <TextField 
                    label="Supplier" 
                    variant="outlined" 
                    className={classes.textField}
                    style={{flexGrow: 1}}
                    value={this.state.SUPPLIER}
                    onChange={(event)=>this.setState({SUPPLIER: event.target.value})}
                    />
                  <Button 
                    variant="outlined" 
                    style={{height: 55, flexShrink: 1, marginLeft: 5}}
                    onClick={()=>this.setState({supplierOpen: true})}
                    >
                    <ListIcon style={{color: '#607d8b', fontSize: 26}}/>
                    </Button>
                </div>
                <div style={{display: 'flex'}}>
                  <TextField 
                    label="Driver" 
                    variant="outlined" 
                    className={classes.textField}
                    style={{flexGrow: 1}}
                    value={this.state.DRIVER}
                    onChange={(event)=>this.setState({DRIVER: event.target.value})}
                    />
                    <Button 
                        variant="outlined" 
                        style={{height: 55, flexShrink: 1, marginLeft: 5}}
                        onClick={()=>this.setState({driverOpen: true})}
                            >
                      <ListIcon style={{color: '#607d8b', fontSize: 26}}/>
                    </Button>
                </div>
                <div style={{display: 'flex'}}>
                  <TextField 
                    label="Car Reg" 
                    variant="outlined" 
                    className={classes.textField}
                    style={{flexGrow: 1}}
                    value={this.state.CARREG}
                    onChange={(event)=>this.setState({CARREG: event.target.value})}
                    />
                    <Button 
                      variant="outlined" 
                      style={{height: 55, flexShrink: 1, marginLeft: 5}}
                      onClick={()=>this.setState({carOpen: true})}
                      >
                      <ListIcon style={{color: '#607d8b', fontSize: 26}}/>
                    </Button>
                </div>
            </Paper>
          </Grid>
          <Grid item xs={12} md={7}>
            
            <Paper className={classes.paper}>
              <div className={classes.title}>
                <MapIcon className={classes.titleIcon}/>
                <Typography variant="h6" className={classes.titleText}>ITINERARY</Typography>
              </div>
                <div >
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={gbLocale}>
                  <Grid container>
                    <Grid item xs={6} style={{display: 'flex', marginBottom: 20}}>
                  <KeyboardDatePicker
                    //clearable
                    label="Date"
                    value={this.state.DATE}
                    placeholder="Expiry Date"
                    onChange={date=>this.setState({DATE: date})}
                    //minDate={new Date()}
                    inputVariant='outlined'
                    format="dd/MM/yyyy"
                    style={{flexGrow: 1,  marginRight: 5, marginTop: 16}}
                  />
                  </Grid>
                  <Grid item xs={6} style={{display: 'flex'}}>
                  <TimePicker
                    margin="normal"
                    id="time-picker"
                    label="Time"
                    ampm={false}
                    value={new Date().setHours((this.state.TIME.substring(0,2) -0), this.state.TIME.substring(3,5))}
                    onChange={date=>this.setState({TIME: format(date, 'HH:mm')})}
                    inputVariant='outlined'
                    style={{flexGrow: 1, marginLeft: 15, marginBottom: 10,}}
                  />
                  </Grid>
                  </Grid>
                </MuiPickersUtilsProvider>
                </div>
              <Divider className={classes.textField}/>
              <TextField 
                label="From" 
                variant="outlined" 
                className={classes.textField}
                value={this.state.FROMTITLE}
                onChange={(event)=>this.setState({FROMTITLE: event.target.value})}
                />
              <TextField 
                label="From - details" 
                multiline rows="3"
                variant="outlined" 
                className={classes.textField}
                value={this.state.FROMDETAILS}
                onChange={(event)=>this.setState({FROMDETAILS: event.target.value})}
                />
              <Divider className={classes.textField}/>
              <TextField 
                label="To" 
                variant="outlined" 
                className={classes.textField}
                value={this.state.TOTITLE}
                onChange={(event)=>this.setState({TOTITLE: event.target.value})}
                />
              <TextField
                label="To - details" 
                multiline rows="3" 
                variant="outlined" 
                className={classes.textField}
                value={this.state.TODETAILS}
                onChange={(event)=>this.setState({TODETAILS: event.target.value})}
                />
            </Paper>


            <Paper className={classes.paper}>
              <div className={classes.title}>
                <NoteIcon className={classes.titleIcon}/>
                <Typography variant="h6" className={classes.titleText}>NOTES</Typography>
              </div>
              <TextField
                label="Notes" 
                multiline rows="3" 
                variant="outlined" 
                className={classes.textField} 
                value={this.state.NOTES}
                onChange={(event)=>this.setState({NOTES: event.target.value})}
                />
              <TextField
                label="Booked by" 
                variant="outlined" 
                className={classes.textField} 
                value={this.state.BOOKEDBY}
                onChange={(event)=>this.setState({BOOKEDBY: event.target.value})}
                />
              <TextField
                label="Driver allocated by" 
                variant="outlined" 
                className={classes.textField} 
                value={this.state.ALLOCATEDBY}
                onChange={(event)=>this.setState({ALLOCATEDBY: event.target.value})}
                />
            </Paper>
          </Grid>
        </Grid>

      </div>



      <Dialog
            open={this.state.supplierOpen}
            TransitionComponent={Transition}
            keepMounted
            //maxWidth="xs"
            fullWidth
            onClose={()=>{this.setState({supplierOpen: false})}}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            
          >
            <DialogTitle id="alert-dialog-slide-title">{"Select Supplier"}</DialogTitle>
            <DialogContent dividers scroll style={{textAlign: 'center'}}>
              <List component="nav" className={classes.list}>
              {
                this.state.suppliers
                .sort((a, b)=>{
                  var nameA=a.NAME.toLowerCase(), nameB=b.NAME.toLowerCase()
                  if (nameA < nameB) //sort string ascending
                      return -1 
                  if (nameA > nameB)
                      return 1
                  return 0 //default return value (no sorting)
                })
                .map((item, index)=>
                <ListItem button key={index} onClick={() => {this.setState({SUPPLIER: item.NAME, supplierOpen: false})}}>
                  <ListItemText primary={
                    <React.Fragment>
                      <Typography
                        component="span"
                        variant="body1"
                        className={classes.inline}
                        color={item.STATUS==='Active'? 'textPrimary': 'textSecondary'}
                      >
                        {item.NAME}
                      </Typography>
                      </React.Fragment>
                      }
                    />
                </ListItem>
                )
              }
              </List>
            </DialogContent>
            <DialogActions>
              <Button onClick={()=>{this.setState({supplierOpen: false})}} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog> 

 
          <Dialog
            open={this.state.driverOpen}
            TransitionComponent={Transition}
            keepMounted
            //maxWidth="xs"
            fullWidth
            onClose={()=>{this.setState({driverOpen: false})}}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            
          >
            <DialogTitle id="alert-dialog-slide-title">{"Select Driver"}</DialogTitle>
            <DialogContent dividers scroll style={{textAlign: 'center'}}>
              <List component="nav" className={classes.list}>
              {
                this.state.drivers
                .sort((a, b)=>{
                  var nameA=a.SURNAME.toLowerCase(), nameB=b.SURNAME.toLowerCase()
                  if (nameA < nameB) //sort string ascending
                      return -1 
                  if (nameA > nameB)
                      return 1
                  return 0 //default return value (no sorting)
                })
                .map((item, index)=>
                <ListItem button key={index} onClick={() => {this.setState({DRIVER: item.SURNAME, CARREG: item.VEHICLE, driverOpen: false})}}>
                  <ListItemText primary={
                    <React.Fragment>
                      <Typography
                        component="span"
                        variant="body1"
                        className={classes.inline}
                        color={item.STATUS==='Active'? 'textPrimary': 'textSecondary'}
                      >
                        {item.NAME + ' ' + item.SURNAME}
                      </Typography>
                      </React.Fragment>
                      }
                      secondary={item.VEHICLE}
                    />
                </ListItem>
                )
              }
              </List>
            </DialogContent>
            <DialogActions>
              <Button onClick={()=>{this.setState({driverOpen: false})}} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>  



        <Dialog
            open={this.state.carOpen}
            TransitionComponent={Transition}
            keepMounted
            //maxWidth="xs"
            fullWidth
            onClose={()=>{this.setState({carOpen: false})}}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            
          >
            <DialogTitle id="alert-dialog-slide-title">{"Select Vehicle"}</DialogTitle>
            <DialogContent dividers scroll style={{textAlign: 'center'}}>
              <List component="nav" className={classes.list}>
              {
                this.state.cars
                .sort((a, b)=>{
                  var nameA=a.REG.toLowerCase(), nameB=b.REG.toLowerCase()
                  if (nameA < nameB) //sort string ascending
                      return -1 
                  if (nameA > nameB)
                      return 1
                  return 0 //default return value (no sorting)
                })
                .map((item, index)=>
                <ListItem button key={index} onClick={() => {this.setState({CARREG: item.REG, carOpen: false})}}>
                  <ListItemText primary={
                    <React.Fragment>
                      <Typography
                        component="span"
                        variant="body1"
                        className={classes.inline}
                        color={item.STATUS==='Active'? 'textPrimary': 'textSecondary'}
                      >
                        {item.REG + ' - ' + item.MAKE + ' ' + item.MODEL}
                      </Typography>
                      </React.Fragment>
                      }
                      secondary={item.KEEPER}
                    />
                </ListItem>
                )
              }
              </List>
            </DialogContent>
            <DialogActions>
              <Button onClick={()=>{this.setState({carOpen: false})}} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>  
      </div>
    );
  }
}

export default withStyles(styles)(inject('appState')(observer(EditBooking)));