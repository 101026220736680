import React, { Component } from 'react';
import { observer, inject } from 'mobx-react'; 
import { withStyles } from '@material-ui/core/styles';
//import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
//import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import FileIcon from '@material-ui/icons/InsertDriveFile';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import Avatar from '@material-ui/core/Avatar';

const styles = theme => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3),
    display: 'flex',
  },
  paper: {
    flexGrow: 1, 
    padding: theme.spacing(2),
    display: 'flex',
    flexFlow: 'column',
  },
  list: {
    flexGrow: 1,
    marginTop: theme.spacing(2)
  },
  title: {
    margin: theme.spacing(4, 0, 2),
    textAlign: 'center',
  },
});

class Invoices extends Component {
  state={
    items: [],
  }

  componentDidMount = () => {
    this.getData();
  }

  getData = () => {
    this.props.appState.appRoutine.loading = true; 

    fetch(this.props.appState.appRoutine.apiUrl + 'invoices/list', 
      {
      method: 'GET', // or 'PUT'
      headers: {'Content-Type': 'application/json'},
      })
      .then(res => res.json())
      .then(
        (result) => {
            this.props.appState.appRoutine.loading = false;
          if(result.status === 'err'){
            alert('error')
            console.log(result);
          }else {
            this.setState({items: result.result.reverse()});
          }
        },
        (error) => {
            this.props.appState.appRoutine.loading = false;
            alert(error)
        }
      )
  }


  handleClick = (filename) => {
    this.props.appState.appRoutine.loading = true; 
    let qry = {
      'filename': filename,
    }

    fetch(this.props.appState.appRoutine.apiUrl + 'invoices/delete', 
      {
      method: 'POST', // or 'PUT'
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(qry)
      })
      .then(res => res.json())
      .then(
        (result) => {
            this.props.appState.appRoutine.loading = false;
          if(result.status === 'err'){
            alert('error')
            console.log(result)
          }else {
            this.props.appState.appRoutine.msg = {
              text: 'Deleted',
              open: true,
              type: 'success',
            }
            this.getData();
          }
        },
        (error) => {
            this.props.appState.appRoutine.loading = false;
            alert(error)
        }
      )
  }



  render() {
    const {classes} = this.props;
    
    return (
        <div container className={classes.root}>
          <Paper className={classes.paper}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h5" className={classes.title}>
                  CREATED INVOICES
                </Typography>
                <div className={classes.demo}>
                  <List>
                      {this.state.items.map(item => (
                      <ListItem>
                        <ListItemAvatar><Avatar><FileIcon /></Avatar></ListItemAvatar>
                        <ListItemText //primary="Single-line item"
                        ><a href={window.location.origin + '/files/invoices/' + item} target="_blank" rel="noopener noreferrer">{item}</a>
                        </ListItemText>
                        <ListItemSecondaryAction>
                          <IconButton edge="end" aria-label="delete" onClick={()=>{this.handleClick(item)}}>
                            <DeleteIcon />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                      ))}
                  </List>
                </div>
              </Grid>
            </Grid>
          </Paper>
        </div>
    );
  }
}



export default withStyles(styles)(inject('appState')(observer(Invoices)));