import React, { Component} from 'react';
import { observer, inject } from 'mobx-react'; 
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import UploadIcon from '@material-ui/icons/CloudUpload';
//import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import DateFnsUtils from '@date-io/date-fns';
import { format } from 'date-fns';
import gbLocale from "date-fns/locale/en-GB";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { KeyboardDatePicker } from "@material-ui/pickers";
import TextField from '@material-ui/core/TextField';

const styles = theme => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(2),
        textAlign: 'center',
      },
      input: {
        display: 'none',
      },
      formControl: {
        margin: theme.spacing(1),
        minWidth: 280,
        marginTop: 40,
        textAlign: 'left',
      },
      
});

class Upload extends Component {
  state={
    open: false,
    nameSize: "none",
    selectedFile: null,
    docName: '',
    docExpiry: new Date(),
    cocNameOtherVisible: false,  
  }

  handleOpen = () => {
    this.setState({
      open: true,
    })
  }

  handleClose = () => {
    this.setState({
      open: false,
    })
  }

  handleFileSelect = (event) => {
    const file = event.target.files[0];

    this.setState({
      selectedFile: file,
      nameSize: file.name + ' (' + parseFloat(file.size / 1024 / 1024).toFixed(2) + ' MB)',
    });
  }

  handleDateChange = (date) => {
    this.setState({docExpiry: date});
  }

  handleChangeDocName = event =>{
    if(event.target.value === 'Other'){
      this.setState({
        docName: event.target.value,
        docNameOtherVisible: true
      });
    }else{
      this.setState({docName: event.target.value});
    }
  }

  handleChangeDocNameOther = event =>{
    this.setState({docName: event.target.value});
  }




  doUpload = () => {
    const data = new FormData() 

    data.append('file', this.state.selectedFile);
    data.append('id', this.props.id);

    let reqPath = 'upload/photo';

    if(this.props.type==='doc'){
      data.append('assignement', this.props.assignement);
      data.append('docName', this.state.docName);
      data.append('docExpiry', format(this.state.docExpiry, 'dd/MM/yyyy'));
      reqPath = 'upload/docs';
    }

    this.props.appState.appRoutine.loading = true;
//
    fetch(this.props.appState.appRoutine.apiUrl + reqPath, 
      {
      method: 'POST', // or 'PUT'
      body: data,
      })
      .then(res => res.json())
      .then(
        (result) => {
            this.props.appState.appRoutine.loading = false;
          if(result.status === 'err'){
            console.log(result);
          };
          if(this.props.type==='photo'){
            this.props.appState.driver.PHOTO = result.filename;
          }
          console.log(result);
          this.props.reload();
          this.handleClose();
        },
        (error) => {
            this.props.appState.appRoutine.loading = false;
            console.log(error)
        }
      )
  }



  render() {
    const {classes} = this.props;
    
    
    return (
      <div className={classes.root}>
          
          {this.props.id ? 
          <Button color="primary" onClick={this.handleOpen}>
              <UploadIcon style={{marginRight: 15}}/> 
              {this.props.title}
          </Button>
          :
          <Button color="primary" disabled>
              <UploadIcon style={{marginRight: 15}}/> 
              {this.props.title}
          </Button>
          }
          <Dialog
            //fullScreen={fullScreen}
            open={this.state.open}
            onClose={this.handleClose}
            aria-labelledby="dialog-title"
            style={{
              textAlign: 'center',
            }}
          >
            <DialogTitle id="dialog-title">{"File Upload"}</DialogTitle>
            <DialogContent style={{textAlign: 'center'}}>
              <DialogContentText>
                Click the button below to select a file
              </DialogContentText>
              <DialogContentText>
                <small>selected:</small>
              </DialogContentText>
              <DialogContentText>
                <b>{this.state.nameSize}</b>
              </DialogContentText>
              <form style={{marginTop: 20}}>
              <input
                accept="image/*"
                className={classes.input}
                id="file"
                name="file"
                type="file"
                onChange={this.handleFileSelect}
              />
              <label htmlFor="file">
                <Button variant="contained" color="primary" component="span" className={classes.controlls}>
                  SELECT A FILE
                </Button>
              </label>
              </form>

            {
              this.props.type==='doc'&&
              <div>
                {this.props.assignement==='vehicle'?
                <FormControl className={classes.formControl}>
                  <InputLabel id="demo-simple-select-label">Document Type</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={this.state.docName}
                    onChange={this.handleChangeDocName}
                  >
                    <MenuItem value="PHV">PHV</MenuItem>
                    <MenuItem value="V5C">V5C</MenuItem>
                    <MenuItem value="MOT">MOT</MenuItem>
                    <MenuItem value="Insurance">Insurance</MenuItem>
                    <MenuItem value="Other">Other</MenuItem>
                  </Select>
                </FormControl>
                :
                <FormControl className={classes.formControl}>
                  <InputLabel id="demo-simple-select-label">Document Type</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={this.state.docName}
                    onChange={this.handleChangeDocName}
                  >
                    <MenuItem value="PHD">PHD</MenuItem>
                    <MenuItem value="PHD Badge">PHD Badge</MenuItem>
                    <MenuItem value="Driving License">Driving License</MenuItem>
                    <MenuItem value="Driving License backside">Driving License backside</MenuItem>
                    <MenuItem value="Other">Other</MenuItem>
                  </Select>
                </FormControl>
                }
                {this.state.docNameOtherVisible===true && 
                <div>
                  <TextField id="standard-basic" label="Document Type" className={classes.formControl} value={this.state.docName} onChange={this.handleChangeDocNameOther}/>
                </div>
                }
                <div>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={gbLocale}>
                <KeyboardDatePicker
                  //clearable
                  value={this.state.docExpiry}
                  placeholder="Expiry Date"
                  onChange={date => this.handleDateChange(date)}
                  minDate={new Date()}
                  format="dd/MM/yyyy"
                  style={{marginTop: 10, width: 280}}
                />
                </MuiPickersUtilsProvider>
                </div>
              </div>
            }
            </DialogContent>
            <DialogActions style={{padding: 20, marginTop: 40}}>
              {this.state.selectedFile===null ?
                <Button autoFocus onClick={this.doUpload} color="primary" disabled>
                  UPLOAD
                </Button>
                :
                <Button autoFocus onClick={this.doUpload} color="primary" >
                  UPLOAD
                </Button>
              }
              <Button onClick={this.handleClose}  autoFocus>
                CANCEL
              </Button>
            </DialogActions>
          </Dialog>
      </div>
    );
  }
}

export default withStyles(styles)(inject('appState')(observer(Upload)));