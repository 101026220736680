import React, { Fragment } from 'react';
import { observer, inject } from 'mobx-react'; 
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ListSubheader from '@material-ui/core/ListSubheader';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import BookingListItem from './BookingListItem';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import SendIcon from '@material-ui/icons/Send';
import TextField from '@material-ui/core/TextField';
import { format } from 'date-fns';
const moment = require('moment');



const styles = theme => ({
  list: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    marginTop: 30,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  root: {
    flexGrow: 1,
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  tab: {
    position: 'absolute',
    flexGrow: 1,
    [theme.breakpoints.up('xs')]: {
      width: 'calc(100% - 30px)',
    },
    [theme.breakpoints.up('sm')]: {
      width: 'calc(100% - 45px)',
    },
    [theme.breakpoints.up('md')]: {
      width: 'calc(100% - 285px)',
    },
    marginTop: 115,
    marginLeft: -5,
    paddingLeft: 5,
    paddingRight: 5,
    backgroundColor: "#ECEFF1",
  },
  mainPaper: {
    marginTop: 40,
    paddingBottom: 60,
  },
  header: {
    textAlign: 'center',
    //paddingTop: theme.spacing.unit * 2,
    padding: theme.spacing(3),
  },
  iconDiv: {
    position: 'absolute',
    marginTop: -35,
    marginLeft: 30,
  },
  iconPaper: {
    backgroundColor: '#E91E63',
    textAllign: 'centre',
    width: 80,
    height: 80,
    borderRadius: 4,
  },
  boxIcon: {
    margin: 20,
    color: 'white',
    fontSize: 40, 
  },
  padding: {
    padding: `0 ${theme.spacing(2)}px`,
  },
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
});


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


class ViewBookingList extends React.Component {
  state={
    expanded: false,
    cancelled: false,
    openMail: false,
    email: '',
    subject: '',
    body: '',
  }
  

  handleExpanded = event =>{
    this.setState({
      expanded: event.target.checked,
    });
  }
  handleCancelled = event =>{
    this.setState({
      cancelled: event.target.checked,
    });
  }

  handleChangeTab = (event, value) => {
    this.setState({ tab: value });
  };

  handleCloseMail=()=>{
    this.setState({
      openMail: false,
    })
  }

  handleConfirm=()=>{
    const transfers = this.props.appState.viewBooking.data.transfers;
    const theRef = transfers[0].REF;
    const txt = `Hello, \n\n` + 
              `We are pleased to confirm the booking as follows: \n\n` + 
              transfers.map(item => {
                let fromDetails = '';
                let toDetails = '';
                let driver = '';
                if(item.FROMDETAILS!==''){fromDetails = `\t\t ${item.FROMDETAILS}\n`}
                if(item.TODETAILS!==''){toDetails = `\t\t ${item.TODETAILS}\n`}
                if(item.DRIVER!==''){driver = `CHECK YOUR DRIVER HERE: https://book.royaltravelclub.co.uk/api/mytrip/${item.drivername}/${item.DRIVER}/${item.driverphone}/${item.driverphv}/${item.CARREG}/${item.driverphoto}`}
                return (
                  `Date: \t ${format(new Date(item.DATE), 'dd-MMM-yyyy')}\n` +
                  `Time: \t ${item.TIME}\n` + 
                  `Name: \t ${item.NAME} X ${item.PAX} pax\n` +
                  `From: \t ${item.FROMTITLE}:\n` +
                  fromDetails +
                  `Dest.: \t ${item.TOTITLE}:\n` + 
                  toDetails +
                  `Vhcl.: \t ${item.CARCLASS}\n` + 
                  `${driver} \n\n`
                  )}) + 
                  `\n\n` + 
                  `Kind regards,\n\n` + 
                  `Royal Travel Club \n` +
                  `M: +44 (0) 74 1234 1334 \n`+
                  `T: +44 (0) 20 8807 6287 \n` +
                  `E: info@royaltravelclub.co.uk \n`+
                  `W: www.royaltravelclub.co.uk \n\n\n` + 
                  `This communication contains information which is confidential and may also be privileged. It is for the exclusive use of the addressee. If you are not the addressee, please note that any distribution, dissemination, copying or use of this communication or the information in it is prohibited. If you have received this message in error, please notify the sender immediately at the above e-mail address or telephone number and delete the information from your computer system. Attachments are virus checked, but the company does not accept any liability in respect of any virus which is not detected.`;

    this.setState({
      subject: 'Confirmation: ' + theRef,
      openMail: true,
      body: txt.replace(',Date', 'Date'),
    });
  }


  sendEmail=()=>{
    this.setState({openMail: true,});

    let qry = {
      "email": this.state.email,
      "subject": this.state.subject,
      "text": this.state.body,
    };

    fetch(this.props.appState.appRoutine.apiUrl + 'email/', 
      {
      method: 'POST', 
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(qry),
      })
      .then(res => res.json())
      .then(
        (result) => {
            this.props.appState.appRoutine.loading = false;
          if(result.status === 'error'){
            this.props.appState.appRoutine.msg = {
              text: 'Error: ' + result.result,
              open: true,
              type: 'error',
            }
            console.log(result.result)
          }else{
            this.props.appState.appRoutine.msg = {
              text: 'Sent',
              open: true,
              type: 'success',
            }
          }
        },
        (error) => {
            this.props.appState.appRoutine.loading = false;
            this.props.appState.appRoutine.msg = {
              text: 'Error: ' + error,
              open: true,
              type: 'error',
            }
            console.log(error)
        }
      )

    this.setState({
      openMail: false,
    })
  }

  handleInvoice=()=>{
    const transfers = this.props.appState.viewBooking.data.transfers;
    const theRef = transfers[0].REF;
    let qry = {
      "ref": theRef,
    };

    fetch(this.props.appState.appRoutine.apiUrl + 'invoices/create', 
      {
      method: 'POST', 
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(qry),
      })
      .then(res => res.json())
      .then(
        (result) => {
            this.props.appState.appRoutine.loading = false;
          if(result.status === 'error'){
            this.props.appState.appRoutine.msg = {
              text: 'Error: ' + result.result,
              open: true,
              type: 'error',
            }
          }else{
            this.props.appState.appRoutine.msg = {
              text: 'Invoice created',
              open: true,
              type: 'success',
            }
          }
        },
        (error) => {
            this.props.appState.appRoutine.loading = false;
            this.props.appState.appRoutine.msg = {
              text: 'Error: ' + error,
              open: true,
              type: 'error',
            }
            console.log(error)
        }
      )
  }

  render() {
    const { classes } = this.props;
    const dates = this.props.appState.viewBooking.dates;
    const data = this.props.appState.viewBooking.data;

    if(data.transfers.length>0){
      return (
        <div>
        <Grid container className={classes.root} spacing={6}>
          <Grid item xs={12}>
          
            <Paper className={classes.mainPaper} elevation={1}>
              <ListSubheader component="div" id="nested-list-subheader" style={{marginBottom: 15, display: 'flex'}}>
              <div style={{flexGrow: 1, display: 'flex', marginTop: 10, marginleft: 10}}>
                <Button color="primary" onClick={this.handleConfirm}>CONFIRM</Button>
                <Button color="primary" onClick={this.handleInvoice}>INVOICE</Button>
              </div>
              <div style={{display: 'flex', textAlign: 'right', paddingTop: 10, marginRight: 10, marginLeft: 10}}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={this.state.expanded}
                          onChange={this.handleExpanded}
                        />
                      }
                      label="Expand"
                    />
                    <FormControlLabel
                      control={
                        <Switch
                          checked={this.state.cancelled}
                          onChange={this.handleCancelled}
                        />
                      }
                      label="Xld"
                    />
                </div>
                </ListSubheader>
                <Divider />

                <div className={classes.list}>
                  {dates.transfers.map((theDate, index)=>
                    <List
                      key={index}
                      component="nav"
                      subheader={
                        <ListSubheader component="div" style={{color: '#E91E63'}}>
                          <div style={{float: "left", width: "50%"}}>{theDate}</div>
                          <div style={{float: "right", width: "50%", textAlign: "right"}}>Total: {data.transfers.filter(transfer => moment(transfer.DATE).format('DD-MMM-YYYY') === theDate).length}</div>
                        </ListSubheader>}
                    >
                      {data.transfers.filter(transfer => moment(transfer.DATE).format('DD-MMM-YYYY') === theDate).map((item, index)=>
                        
                        <div key={index}>
                          {(this.state.cancelled===false && item.STATUS === 'CANCELLED') ? 
                          <Fragment>
                          </Fragment>
                          :
                          <Fragment>
                            <Divider light/>
                            <BookingListItem item={item} expanded={this.state.expanded} refresh={this.props.refresh}/>
                          </Fragment>
                          }
                        </div>
                        
                      )}
                    </List>
                  )}
                </div>
              
            </Paper>
          </Grid>
        </Grid>


        <Dialog fullScreen open={this.state.openMail} onClose={this.handleCloseMail} TransitionComponent={Transition}>
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton edge="start" color="inherit" onClick={this.handleCloseMail} aria-label="close">
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" className={classes.title}>
                e-mail
              </Typography>
              <Button autoFocus color="inherit" endIcon={<SendIcon/>} onClick={this.sendEmail}>
                SEND
              </Button>
            </Toolbar>
          </AppBar>
          <List>
            <ListItem style={{display: 'flex'}}>
              <TextField 
                id="e-mail" 
                label="e-mail" 
                variant="outlined" 
                style={{flexGrow: 1}} 
                value={this.state.email}
                onChange={(event)=>{this.setState({email: event.target.value})}}
                />
            </ListItem>
            <ListItem style={{display: 'flex'}}>
              <TextField 
                id="subject" 
                label="subject" 
                variant="outlined" 
                style={{flexGrow: 1}} 
                value={this.state.subject}
                onChange={(event)=>{this.setState({subject: event.target.value})}}
                />
            </ListItem>
            <ListItem style={{display: 'flex'}}>
              <TextField 
                id="text" 
                label="text" 
                variant="outlined" 
                multiline rows="25" 
                style={{flexGrow: 1, marginTop: 8}} 
                value={this.state.body}
                onChange={(event)=>{this.setState({body: event.target.value})}}
                />
            </ListItem>
          </List>
        </Dialog>
        </div>
      );
  }else{
    return (
      <Grid container className={classes.root} spacing={6}>
        <Grid item xs={12}>
          <Paper elevation={1}>
            <div>
                <Typography variant="subtitle1" className={classes.header}>
                  No services found
                </Typography>
            </div>
          </Paper>
        </Grid>
      </Grid>
    );
  }
  }
}

ViewBookingList.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(inject('appState')(observer(ViewBookingList)));