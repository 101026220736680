import { observable, action, decorate } from "mobx";

class AppState {
  count = 0;
  appRoutine = {
    count: 2,
    //apiUrl: "http://localhost:5000/api/",
    apiUrl: "https://book.royaltravelclub.co.uk/api/",
    //apiUrl: "http://book.deluxemotion.co.uk/api/",
    //logedIn: true,
    logedIn: false,
    currentPage: "Home",
    version: '1.4.5',
    loading: false,
    msgOpen: false,
    msgText: '',
    msg: {
      open: false, 
      text: '',
      type: 'info',
    }

  }
  user = {};
  supplier = {};
  vehicle = {};
  driver = {};
  bookings = {
    date: '',
    activeSearch: false,
    period: 'month',
    searchBy: 'NAME',
    searchFor: '',
    expandAll: false,
    data: {
      transfers: {},
    },
    dates: {
      transfers: {},
    },
  };
  
  viewBooking = {
    ref: '',
    expandAll: false,
    data: {
      transfers: {},
    },
    dates: {
      transfers: {},
    },
  };

  increment() {
    this.count++;
  }

  decrement() {
    this.count--;
  }


  login(userDetails){
    this.appRoutine.logedIn = true;
    this.user = userDetails;
  }
  
  logout(){
    this.appRoutine.logedIn = false;
    this.user = {};
  }

  changePage(newPage){
    this.appRoutine.currentPage = newPage; 
  }
}

decorate(AppState, {
  appRoutine: observable,
  user: observable,
  supplier: observable,
  driver: observable,
  vehicle: observable,
  bookings: observable,
  viewBooking: observable,
  count: observable,
  increment: action,
  decrement: action,
  changePage: action,
  login: action,
  logout: action,
})

export default AppState;