import React, { Component } from 'react';
import { observer, Provider } from 'mobx-react'
import './App.css';
import AppState from './AppState';
import AppRoutes from './AppRoutes';


class App extends Component {
  render() {
    return (
      <Provider appState={new AppState()}>
        <AppRoutes/>
      </Provider>
    );
  }
}


export default observer(App);
