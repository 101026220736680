import React, { Component } from 'react';
import { observer, inject } from 'mobx-react'
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import DocIcon from '@material-ui/icons/Description';
//import { format } from 'date-fns';

const styles = theme => ({
    root: {
        //flexGrow: 1,
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        //textAlign: 'center',
      },
      paper: {
        flexGrow: 1,
        padding: theme.spacing(2),
        marginBottom: 20,
      },
      icon: {
        fontSize: 30,
        padding: theme.spacing(2),
      },
    });


class DocsMonitor extends Component {
    state={
        items: [],
    }

    componentDidMount = () => {
        this.getData();
    }

    openItem = (item) => {
        if(this.props.assignement==='vehicle'){
            this.props.appState.vehicle = item;
        }else{
            this.props.appState.driver = item;
        }
    }

    getData = () => {

        const qry = {
        'assignement': this.props.assignement,
        }

        fetch(this.props.appState.appRoutine.apiUrl + 'documents/monitor', 
        {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(qry),
        })
        .then(res => res.json())
        .then(
            (res) => {
            if(res.result.length > 0){
                this.setState({items: res.result});
            } else if(res.status === 'err'){
                console.log(res)
            } 
            },
            (error) => {
            alert(error);
            }
        )
    }


  render() {
    const {classes} = this.props;


    return (
      <div className="root">
        {this.state.items.map((item, index) => {
          const daysLeft = Math.ceil((new Date (item.DOCEXPIRY) - Date.now()) / (1000*60*60*24));
          let color = 'Teal'; 
          if(daysLeft > 0 && daysLeft < 10){color = 'DarkOrange'}
          else if(daysLeft < 1){color = 'Brown'}

          return (
            <Paper className={classes.paper} key={index}>
                <Grid container spacing={3}>
                <Grid item xs={3} >
                    <DocIcon className={classes.icon} style={{color: color}}/>
                </Grid>
                <Grid item xs={9} md={7} style={{textAlign: 'left', paddingTop: 21}} >
                    <Typography variant='body1'>{item.DOCNAME}</Typography>
                    <Typography variant='body2'><small>Document is expiring in:</small> {daysLeft} days</Typography>
                </Grid>
                <Grid item xs={12} md={2} style={{textAlign: 'right', paddingTop: 26, paddingRight: 10}}>
                    <Button color="primary" onClick={()=>{this.openItem(item)}}>open</Button>
                </Grid>
                </Grid>
            </Paper>
          )}
        )}
      </div>
    );
  }
}

export default withStyles(styles)(inject('appState')(observer(DocsMonitor)));