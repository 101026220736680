import React, { Component } from 'react';
import { observer, inject } from 'mobx-react'; 
import { withStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
//import Avatar from '@material-ui/core/Avatar';
import MailIcon from '@material-ui/icons/AlternateEmail';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import SendIcon from '@material-ui/icons/Send';
import TextField from '@material-ui/core/TextField';
import { format } from 'date-fns';

const styles = theme => ({
  root: {
    //flexGrow: 1,
    //display: 'flex',
    //justifyContent: 'center',
    padding: theme.spacing(3),
  },
  chip: {
    margin: 3,
  },
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
});


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class Jobs extends Component {
  state={
    openMail: false,
    drivers: [],
    theDriver: {
      name: '',
      surname: '',
      email: '',
      jobs: [],
    },
    subject: '',
    body: ''
  }

  componentDidMount = () => {
    this.getData();
  }

  getData = () => {
    fetch(this.props.appState.appRoutine.apiUrl + 'drivers/listactive', 
      {
      method: 'GET',
      headers: {'Content-Type': 'application/json'},
      })
      .then(res => res.json())
      .then(
        (res) => {
          this.setState({drivers: res.result});
        },
        (error) => {
          alert(error);
        }
      )
  }


handleClick=(driver)=>{
  const thisDriver = this.state.drivers.find(obj=>obj.SURNAME===driver);
  if(!thisDriver){
    this.props.appState.appRoutine.msg = {
      text: 'Error: selected driver is inactive.',
      open: true,
      type: 'error',
    }
    return
  }
  console.log(thisDriver)
  const jobs = this.props.appState.bookings.data.transfers.filter(obj=>obj.DRIVER===driver && obj.STATUS!=='CANCELLED');
  const theDates = jobs.map(item => {
    return (format(new Date(item.DATE), 'dd-MMM-yyyy'));
  });
  const subject = 'Jobs: ' + Array.from(new Set(theDates)).join(', ');
  const txt = `Hi ${thisDriver.NAME}, \n\n` + 
              `Please accept and confirm the jobs as follows below: \n\n` +
              jobs.map(item => {
                let fromDetails = '';
                let toDetails = '';
                if(item.FROMDETAILS!==''){fromDetails = `\t\t ${item.FROMDETAILS.replace(/\n/g, '\n\t\t')}\n`}
                if(item.TODETAILS!==''){toDetails = `\t\t ${item.TODETAILS.replace(/\n/g, '\n\t\t')}\n`}
                return (
                  `--------------------------------------------------------------------\n\n` + 
                  `Date: \t ${format(new Date(item.DATE), 'dd-MMM-yyyy')}\n` +
                  `Time: \t ${item.TIME}\n` + 
                  `Name: \t ${item.NAME} X ${item.PAX} pax\n` +
                  `From: \t ${item.FROMTITLE}:\n` +
                  fromDetails +
                  `Dest.: \t ${item.TOTITLE}:\n` + 
                  toDetails +
                  `Notes:\t ${item.NOTES.replace(/\n/g, '\n\t\t')}\n\n`
                  )})
               + 
              `--------------------------------------------------------------------\n` + 
              `\n\n` + 
              `Kind regards,\n\n` + 
              `Royal Travel Club \n` +
              `M: +44 (0) 74 1234 1334 \n`+
              `T: +44 (0) 20 8807 6287 \n` +
              `E: info@royaltravelclub.co.uk \n`+
              `W: www.royaltravelclub.co.uk \n\n\n` + 
              `This communication contains information which is confidential and may also be privileged. It is for the exclusive use of the addressee. If you are not the addressee, please note that any distribution, dissemination, copying or use of this communication or the information in it is prohibited. If you have received this message in error, please notify the sender immediately at the above e-mail address or telephone number and delete the information from your computer system. Attachments are virus checked, but the company does not accept any liability in respect of any virus which is not detected.`;
  this.setState({
    theDriver: {
      name: thisDriver.NAME,
      surname: thisDriver.SURNAME,
      email: thisDriver.EMAIL,
      jobs: jobs,
    },
    openMail: true,
    subject: subject,
    body: txt.replace(',Date', 'Date').replace(/,-/g, '-'),
  });
}


handleCloseMail=()=>{
  this.setState({
    openMail: false,
  })
}


sendEmail=()=>{

  let qry = {
    "email": this.state.theDriver.email,
    "subject": this.state.subject,
    "text": this.state.body
  };

  fetch(this.props.appState.appRoutine.apiUrl + 'email/',
    {
    method: 'POST', // or 'PUT'
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(qry)
    })
    .then(res => res.json())
    .then(
      (result) => {
          this.props.appState.appRoutine.loading = false;
        if(result.status === 'error'){
          this.props.appState.appRoutine.msg = {
            text: 'Error: ' + result.result,
            open: true,
            type: 'error',
          }
          console.log(result)
        }else{
          this.props.appState.appRoutine.msg = {
            text: 'Sent',
            open: true,
            type: 'success',
          }
        }
      },
      (error) => {
          this.props.appState.appRoutine.loading = false;
          this.props.appState.appRoutine.msg = {
            text: 'Error: ' + error,
            open: true,
            type: 'error',
          }
          console.log(error)
      }
    )

  this.setState({
    openMail: false,
  })
}





  render() {
    const {classes} = this.props;
    const drivers = new Set(Array.from(this.props.appState.bookings.data.transfers).filter(obj=>obj.DRIVER!=='').map(obj=>obj.DRIVER));

    return (
      <div>
        <div className={classes.root}>
          {Array.from(drivers).map((item, index)=>
            <Chip
              id={item}
              key={index}
              deleteIcon={<MailIcon />}
              label={item}
              clickable
              onClick={()=>this.handleClick(item)}
              onDelete={()=>this.handleClick(item)}
              className={classes.chip}
          />
          )}     
        </div>

        <Dialog fullScreen open={this.state.openMail} onClose={this.handleCloseMail} TransitionComponent={Transition}>
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton edge="start" color="inherit" onClick={this.handleCloseMail} aria-label="close">
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" className={classes.title}>
                e-mail
              </Typography>
              <Button autoFocus color="inherit" endIcon={<SendIcon/>} onClick={this.sendEmail}>
                SEND
              </Button>
            </Toolbar>
          </AppBar>
          <List>
            <ListItem style={{display: 'flex'}}>
              <TextField 
                id="e-mail" 
                label="e-mail" 
                variant="outlined" 
                style={{flexGrow: 1}} 
                value={this.state.theDriver.email}
                onChange={(event)=>{this.setState({theDriver: {email: event.target.value}})}}
                />
            </ListItem>
            <ListItem style={{display: 'flex'}}>
              <TextField 
                id="subject" 
                label="subject" 
                variant="outlined" 
                style={{flexGrow: 1}} 
                value={this.state.subject}
                onChange={(event)=>{this.setState({subject: event.target.value})}}
                />
            </ListItem>
            <ListItem style={{display: 'flex'}}>
              <TextField 
                id="text" 
                label="text" 
                variant="outlined" 
                multiline rows="20" 
                style={{flexGrow: 1, marginTop: 8}} 
                value={this.state.body}
                onChange={(event)=>{this.setState({body: event.target.value})}}
                />
            </ListItem>
          </List>
        </Dialog>
      </div>
    );
  }
}



export default withStyles(styles)(inject('appState')(observer(Jobs)));